







































































































































































































































































































































































































































































import { ApplicationUserModel, AuditExplanationTypeEnum, AuditModel, CategoryModel, EscalationTypeEnum, FindingManagerModel, FindingModel, ImplementationStateEnum, PermissionTypeEnum, PlanItemModel, QuestionFileModel, QuestionGroupModel, ResponseFileModel, ResponseFileType, ResponseModel, ResponseTypeEnum, ResponseValueModel, TenantModel, TenantUserModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { questionGroupsStore } from '@/libs/question-groups/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { QuestionGroupResponse } from '../model/QuestionGroupResponse';
import { Guid } from '@/libs/common/functions/guid';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { cloneDeep, find, isEqual } from 'lodash';
import WorkTaskDialogFiles from '../components/WorkTaskDialogFiles.vue';
import QuestionFileItem from '@/libs/audits/components/QuestionFileItem.vue';
import QuestionFileDetail from '@/libs/audits/components/QuestionFileDetail.vue';
import { categoriesStore } from '@/libs/categories/+state/store';
import { auditsStore } from '@/libs/audits/+state/store';

@Component({
    components: {
        WorkTaskDialogFiles, QuestionFileItem, QuestionFileDetail
    }
})
export default class WorkTaskAuditTab extends Page {

    valid = true;
    questionGroups: Array<QuestionGroupModel> = [];
    items: Array<QuestionGroupResponse> = [];
    itemsOriginal: Array<QuestionGroupResponse> = [];
    searchValue = "";
    finishedPercent = 0;
    loading = true;
    item: PlanItemModel | null = null;
    category: CategoryModel | null = null;
    audit: AuditModel | null= null;
    instructionsDialog = false;
    
    showResponseValue: string | null = null;
    currentQuestionFile: any = {
        questionGroupIndex: 0,
        questionIndex: 0,
        fileIndex: 0,
        canPrev: false,
        canNext: false,
        file: null
    };
    correctionManagersRules = [(v: any[]) => !!v && v.length > 0 || this.$i18n.t('common.thisFileIsRequired') ];

    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    @Ref() questionFileDetail!: QuestionFileDetail;
    @Prop() model!: PlanItemModel;
    @Prop() categoryModel!: CategoryModel;
    @Prop() id!: string;
    @Prop() auditId!: string;
    @Prop() auditModel!: AuditModel;

    @Watch("items", { deep: true })
    itemsChanged () {
        this.$emit("change");
    }

    @Watch("model")
    modelChanged (v: PlanItemModel | null) {
        this.item = v;
    }

    @Watch("item")
    itemChanged (v: PlanItemModel | null) {
        this.$emit("update:model", v);
    }

    @Watch("categoryModel")
    categoryModelChanged (v: CategoryModel | null) {
        this.category = v;
    }

    @Watch("category")
    categoryChanged (v: CategoryModel | null) {
        this.$emit("update:categoryModel", v);
    }

    get canShowWaitingQuestionOption () {
        return (this.item != null && this.item.responseLock == false);
    }

    get canEditWorkplace () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.item != null && this.item.tenantUserId == this.tenantUser.id && this.item.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditQuestions () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.item != null && this.item.tenantUserId == this.tenantUser.id && this.item.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditFiles () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.item != null && this.item.tenantUserId == this.tenantUser.id && this.item.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canShowCorrectiveMeasures () {
        return (this.item != null && this.item.responseLock == true);
    }

    get canEditCorrectiveMeasurements () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.item != null && this.item.responseLock == true && this.item.fullLock == false && permissions.contains(PermissionTypeEnum.Findings)) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get totalScorePercent(): string {
        if (this.item) {
            if (this.item.responseLock == true) {
                if (this.item!.audit!.category!.responseType == 'OkNok' || this.item!.audit!.category!.responseType == 'OkNokWarning') {
                    const responseCount = this.item.responseCount!;
                    const okCount = this.item.okCount! + this.item.okWarningCount!;
                    if (responseCount == 0)
                        return "100%";
                    return (okCount / responseCount * 100).toFixed(2) + "%";
                }
                else {
                    const scoreValue = this.item.scoreValue!;
                    const scoreMaxValue = this.item.scoreMaxValue!;
                    if (scoreValue == null || scoreMaxValue == null)
                        return "--";
                    if (scoreMaxValue == 0)
                        return "100%";
                    return (scoreValue / scoreMaxValue * 100).toFixed(2) + "%";
                }
            }
            else {
                if (this.item!.audit!.category!.responseType == 'OkNok' || this.item!.audit!.category!.responseType == 'OkNokWarning') {
                    const responseCount = this.items.sum(x => x.responses!.where(n => n.okValue != -1).count());
                    const okCount = this.items.sum(x => x.responses!.where(n => n.okValue != -1).count(n => n.okValue == 1 || n.okValue == 2));
                    if (responseCount == 0)
                        return "100%";
                    return (okCount / responseCount * 100).toFixed(2) + "%";
                }
                else {
                    const responseCount = this.items.sum(x => x.responses!.where(n => n.okValue != -1).count());
                    let maxScorePerQuestions = 0;
                    switch (this.category!.responseType) {
                        case ResponseTypeEnum.ScoreOneToFive:
                            maxScorePerQuestions = 5;
                            break;
                        case ResponseTypeEnum.ScoreOneToThree:
                            maxScorePerQuestions = 3;
                            break;
                        case ResponseTypeEnum.ScoreZeroToFive:
                            maxScorePerQuestions = 5;
                            break;
                        case ResponseTypeEnum.ScoreZeroToFour:
                            maxScorePerQuestions = 4;
                            break;
                        case ResponseTypeEnum.ScoreZeroToOne:
                            maxScorePerQuestions = 1;
                            break;
                        case ResponseTypeEnum.ScoreZeroToTwo:
                            maxScorePerQuestions = 2;
                            break;
                        case ResponseTypeEnum.VDA63:
                            maxScorePerQuestions = 10;
                            break;
                    }
                    const scoreValue = this.items.sum(x => x.responses!.where(n => n.scoreValue != -1).sum(p => p.scoreValue ?? 0));
                    const scoreMaxValue = this.items.sum(x => x.responses!.where(n => n.scoreValue != -1).sum(p => maxScorePerQuestions));
                    if (scoreValue == null || scoreMaxValue == null)
                        return "--";
                    if (scoreMaxValue == 0)
                        return "100%";
                    return (scoreValue / scoreMaxValue * 100).toFixed(2) + "%";
                }
            }
        }
        return "--";
    }

    get storedPlanItem (): PlanItemModel {
        return planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel;
    }

    canEditImplementationOfCorrectiveMeasurements (response: ResponseModel) {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.item != null && this.item.responseLock == true && this.item.fullLock == false && permissions.contains(PermissionTypeEnum.Measures) && response.implementerUserId == this.tenantUser.id) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }
    
    get isEnabledCommentOnOkResponse() {
        const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
        return tenant.isEnabledCommentOnOkResponse == true;
    }

    canEditAnyImplementationOfCorrectiveMeasurements () {
        if (this.item == null || this.item.responses == null)
            return false;
        return this.item.responses.any(x => this.canEditImplementationOfCorrectiveMeasurements(x));
    }

    isUsingNotRatedResponseOption () {
        return this.items.any(x => x.responses?.any(r => this.canShowNotRatedQuestionOption(r)) == true);
    }

    canShowNotRatedQuestionOption (response: ResponseModel) {
        if (this.category!.useNotRatedOption) {
            return true;
        }
        else if ((this.category!.responseType == 'OkNok' || this.category!.responseType == 'OkNokWarning') && response.okValue == -1) {
            return true;
        }
        else if ((this.category!.responseType == 'ScoreZeroToOne' || this.category!.responseType == 'ScoreZeroToTwo' || this.category!.responseType == 'ScoreOneToThree' || this.category!.responseType == 'ScoreZeroToFour' || this.category!.responseType == 'ScoreZeroToFive' || this.category!.responseType == 'ScoreOneToFive') && response.scoreValue == -1) {
            return true;
        }
        return false;
    }

    isShowNotRatedQuestionOption (response: ResponseModel) {
        if ((this.category!.responseType == 'OkNok' || this.category!.responseType == 'OkNokWarning') && response.okValue == -1) {
            return true;
        }
        else if ((this.category!.responseType == 'ScoreZeroToOne' || this.category!.responseType == 'ScoreZeroToTwo' || this.category!.responseType == 'ScoreOneToThree' || this.category!.responseType == 'ScoreZeroToFour' || this.category!.responseType == 'ScoreZeroToFive' || this.category!.responseType == 'ScoreOneToFive') && response.scoreValue == -1) {
            return true;
        }
        return false;
    }

    get requiredRules() {
        return [
            (v: any) => !!v || "Pole je vyžadováno"
        ];
    }

    get unansweredQuestionsCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if ((!response.scoreValue && response.scoreValue != 0) && !response.okValue)
                    result++;
            });
        });
        return result;
    }

    get missingDescriptionsCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.description == '')
                    result++;
            });
        });
        return result;
    }

    get missingImmediateMeasuresCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.immediateMeasures == '')
                    result++;
            });
        });
        return result;
    }

    get missingEscalationDescriptionCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                response.findings?.forEach((finding: ResponseModel) => {
                    if (response.isOk == false && (finding.description == '' || finding.description == null))
                        result++;
                });
            });
        });
        return result;
    }

    get okResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk && response.okValue == 1)
                    result++;
            });
        });
        return result;
    }

    get nokResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk == false)
                    result++;
            });
        });
        return result;
    }

    get okWithWarningResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk && response.okValue == 2)
                    result++;
            });
        });
        return result;
    }

    get scoreZeroResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 0)
                    result++;
            });
        });
        return result;
    }

    get scoreOneResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 1)
                    result++;
            });
        });
        return result;
    }

    get scoreTwoResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 2)
                    result++;
            });
        });
        return result;
    }

    get scoreThreeResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 3)
                    result++;
            });
        });
        return result;
    }

    get scoreFourResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 4)
                    result++;
            });
        });
        return result;
    }

    get scoreFiveResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 5)
                    result++;
            });
        });
        return result;
    }

    get scoreSixResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 6)
                    result++;
            });
        });
        return result;
    }

    get scoreEightResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 8)
                    result++;
            });
        });
        return result;
    }

    get scoreTenResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 10)
                    result++;
            });
        });
        return result;
    }

    get notRatedResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (this.category!.responseType == 'OkNok' || this.category!.responseType == 'OkNokWarning') {
                    if (response.okValue == -1) {
                        result++;
                    }
                }
                else {
                    if (response.scoreValue == -1) {
                        result++;
                    }
                }
            });
        });
        return result;
    }

    get newId() {
        return Guid.EmptyGuid();
    }

    @Ref() form!: any;

    mounted() {
        this.loading = true;
        if (this.id && this.id != Guid.EmptyGuid()) {
            this.loadPlanItem().then((item: PlanItemModel) => {
                this.loadAudit(item.auditId!).then((e: AuditModel) => {
                this.audit = e;
                    this.loadCategory(this.audit.categoryId!).then((e: CategoryModel) => {
                        this.category = e;
                        this.loadQuestionGroups().then((e) => {
                            this.make();
                            this.loading = false;
                        }).catch((e: any) => {
                            console.log(e);
                        });
                    }).catch((e) => {
                        console.log(e);
                    });
                }).catch((e) => {
                        console.log(e);
                    });
            }).catch((e) => {
                console.log(e);
            });
        }
        else {
            this.item = this.model;
            this.category = this.categoryModel;
            this.audit = this.auditModel;
            this.loadQuestionGroups().then((e) => {
                this.make();
                this.loading = false;
            }).catch((e: any) => {
                console.log(e);
            });
        }
    }

    refresh () {
        this.loadPlanItem().then(() => {
            this.loadAudit(this.auditId!).then((e: AuditModel) => {
                this.audit = e;
                this.loadCategory(this.audit.categoryId!).then((e: CategoryModel) => {
                    this.category = e;
                    this.loadQuestionGroups().then((e) => {
                        this.make();
                        this.loading = false;
                    }).catch((e: any) => {
                        console.log(e);
                    });
                }).catch((e) => {
                    console.log(e);
                });
            }).catch((e: any) => {
                console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        });
    }

    isChanged() {
        return !isEqual(this.itemsOriginal, this.items);
    }

    showResponseValues (value: string) {
        if (value != this.showResponseValue)
            this.showResponseValue = value;
        else
            this.showResponseValue = null;
    }

    loadPlanItem(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            planItemsStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(planItemsStore, CrudReponse.Get).then((e: PlanItemModel) => {
                this.item = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadAudit(auditId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            auditsStore.dispatch(CrudAction.Get, { id: auditId });
            this.subscribe(auditsStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private loadQuestionGroups (): Promise<any> {
        return new Promise((resolve, reject) => {
            questionGroupsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<QuestionGroupModel>({ field: "position", index: 1, order: "asc" }, [
                {field: "auditId", op: "eq", comparand: this.audit!.id!}
            ], undefined, false, "WorkTaskDialog"));
            this.subscribe(questionGroupsStore, CrudReponse.GetAll, "WorkTaskDialog").then((e: QuestionGroupModel[]) => {
                e.forEach(g => {
                    if (g.questions)
                        g.questions.sort((a, b) => a.position! - b.position!);
                });
                this.questionGroups = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private make () {
        this.items = this.questionGroups.where(q => (this.item != null && this.item.responses && this.item.responses.any(x => x.question != null && x.question.questionGroupId == q.id)) || (this.item != null && !this.item.isAnswered)).toArray().map((e) => {
            const n = {
                id: e.id,
                auditId: e.auditId,
                name: e.name,
                position: e.position,
                responses: e.questions ? e.questions.where(q => (this.item != null && ((this.item.responses && this.item.responses.any(x => x.questionId == q.id)) || !this.item.isAnswered))).toArray().map((q) => {
                    if (this.item && this.item.responses) {
                        var response = this.item.responses.firstOrDefault(x => x.questionId == q.id) as any;
                        var responseObject = this.item.responses.firstOrDefault(x => x.questionId == q.id) as any;
                        if (responseObject != null) {
                            responseObject.findings = responseObject.findings.orderBy((x: FindingModel) => x.sequenceIndex).toArray();
                            responseObject.auditFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.Response).toArray();
                            responseObject.correctiveMeasureFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.CorrectiveMeasure).toArray();
                            responseObject.correctiveMeasureImplementationFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.CorrectiveMeasureImplementation).toArray();
                            return responseObject;
                        }
                        return {
                            id: Guid.EmptyGuid(),
                            isOk: null,
                            questionId: q.id,
                            question: q,
                            value: "",
                            auditFiles: [],
                            correctiveMeasureFiles: [],
                            correctiveMeasureImplementationFiles: [],
                            okValue: null,
                            immediateMeasures: null,
                            escalationDescription: null,
                            description: null,
                            findings: [],
                            notRatedReason: null
                        } as ResponseModel;
                    }
                    else {
                        return null;
                    }
                }).where(x => x != null).toArray() : []
            } as QuestionGroupResponse;
            return n;
        }) as Array<QuestionGroupResponse>;
        this.itemsOriginal = cloneDeep(this.items);
    }

    public responseChanged(response: ResponseModel) {
        let responseValue: ResponseValueModel | null = null;
        if (this.category!.responseType == ResponseTypeEnum.OkNok || this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.okValue) ?? null;
        }
        else {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.scoreValue) ?? null;
        }
        if (responseValue == null) {
            response.isOk = null;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.RequiredEscalation) {
            response.isOk = false;
            response.immediateMeasures = '';
            response.findings = [{
                id: Guid.EmptyGuid(),
                description: null,
                cause: null,
                findingManagers: [],
                findingFiles: []
            } as FindingModel];
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.OptionalEscalation) {
            response.isOk = true;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
        else {
            response.isOk = true;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
    }
    
    private isRequiredFinding(response: ResponseModel): boolean {
        let responseValue: ResponseValueModel | null = null;
        if (this.category!.responseType == ResponseTypeEnum.OkNok || this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.okValue) ?? null;
        }
        else {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.scoreValue) ?? null;
        }
        if (responseValue == null) {
            return false;
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.RequiredEscalation) {
            return true;
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.OptionalEscalation) {
            return false;
        }
        else {
            return false;
        }
    }

    public canEscalate (response: ResponseModel) {
        let responseValue: ResponseValueModel | null = null;
        if (this.category!.responseType == ResponseTypeEnum.OkNok || this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.okValue) ?? null;
        }
        else {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.scoreValue) ?? null;
        }
        return responseValue?.escalationType == EscalationTypeEnum.OptionalEscalation;
    }

    public escalate (response: ResponseModel) {
        response.isOk = false;
        response.findings = [{
            id: Guid.EmptyGuid(),
            description: null,
            cause: null,
            useDefinedManagers: false,
            findingManagers: [],
            findingFiles: []
        } as FindingModel];
    }

    public cancelEscalation (response: ResponseModel) {
        response.isOk = true;
    }

    public plainToHtml (value: string) {
        return value?.replace("\n", "<br />");
    }

    private getExplanations(questionGroup: QuestionGroupModel) {
        let explanations: any[] = [];
        if (this.item!.audit!.explanationType == AuditExplanationTypeEnum.ByCategory) {
            explanations = this.item!.audit!.category!.responseValues!.map(x => {
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: x.explanationDescription
                }
            }).toArray();
        }
        else if (this.item!.audit!.explanationType == AuditExplanationTypeEnum.ByAudit) {
            explanations = this.item!.audit!.category!.responseValues!.map(x => {
                const auditExplanation = this.audit!.auditExplanations!.firstOrDefault(n => n.responseValue == x.value);
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: auditExplanation?.explanationDescription
                }
            }).toArray();
        }
        else if (this.item!.audit!.explanationType == AuditExplanationTypeEnum.ByQuestionGroup) {
            explanations = this.item!.audit!.category!.responseValues!.map(x => {
                const filteredQuestionGroup = this.questionGroups.first(n => n.id == questionGroup.id);
                const questionGroupExplanation = filteredQuestionGroup!.questionGroupExplanations?.firstOrDefault(n => n.responseValue == x.value);
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: questionGroupExplanation?.explanationDescription
                }
            }).toArray();
        }
        if (!explanations.any(x => x.explanationDescription != null && x.explanationDescription.toString().length > 0)) {
            return [];
        }
        else {
            return explanations;
        }
    }

    private canShowExplanations(questionGroup: QuestionGroupModel) {
        
        if (this.audit?.explanationType == AuditExplanationTypeEnum.None || this.audit?.explanationType == null)
            return false;

        return this.getExplanations(questionGroup).length > 0;

    }

    showQuestionFileDetail (questionGroupIndex: number, questionIndex: number, fileIndex: number) {
        this.currentQuestionFile = {
            questionGroupIndex: questionGroupIndex,
            questionIndex: questionIndex,
            fileIndex: fileIndex,
            canPrev: fileIndex > 0 ? true : false,
            canNext: fileIndex + 1 < this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length ? true : false,
            file: this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles![fileIndex]
        };
        console.log(this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length);
        if (this.currentQuestionFile)
            this.questionFileDetail.open(this.currentQuestionFile);
    }

    prevQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex - 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex--;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = true;
        }
    }

    nextQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex + 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex++;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = this.currentQuestionFile.fileIndex + 1 < this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles!.length ? true : false;
        }
    }

    validate () {
        return this.form.validate();
    }

    getResponses(): Array<ResponseModel> {
        const responses: Array<ResponseModel> = [];
        for (const item of this.items) {
            if (item.responses) {
                item.responses.forEach((e: any) => {
                    if (!this.isRequiredFinding(e)) {
                        e.findings = e.findings.where((f: any) => f.description != null && f.description != '').toArray();
                    }
                    e.sequenceIndex = item.responses!.indexOf(e) + 1;
                    e.responseFiles = [];
                    e.auditFiles.forEach((f: any) => {
                        e.responseFiles.push(f);
                    });
                    e.correctiveMeasureFiles.forEach((f: any) => {
                        e.responseFiles.push(f);
                    });
                    e.correctiveMeasureImplementationFiles.forEach((f: any) => {
                        e.responseFiles.push(f);
                    });
                    if (e.isOk == true) {
                        e.escalationDescription = "";
                        e.implementerUserId = null;
                        e.implementationState = ImplementationStateEnum.None;
                        e.implementationTime = null;
                    }
                    if (!this.item!.responseLock) {
                        if (e.isOk == false || e.immediateMeasures != null) {
                            e.hasDisagreement = true;
                        }
                    }
                    e.findings = e.findings.toArray();
                    for (const finding of e.findings) {
                        finding.sequenceIndex = e.findings.indexOf(finding) + 1;
                        if (!finding.useDefinedManagers) {
                            finding.findingManagers = [];
                        }
                    }
                    responses.push(e);
                });
            }
        }
        return responses;
    }

    addFinding (response: ResponseModel) {
        response.findings!.push({
            id: Guid.EmptyGuid(),
            description: null,
            cause: null,
            useDefinedManagers: false,
            findingManagers: [],
            findingFiles: []
        } as FindingModel);
    }

    getNotFinishedResponses(): Array<ResponseModel> {
        let notFinishedResponses: Array<ResponseModel> = [];
        for (const item of this.items) {
            if (item.responses)
                item.responses.forEach((e: any) => {
                    if (e.okValue == null && e.scoreValue == null) {
                        notFinishedResponses.push(e);
                    }
                });
        }
        return notFinishedResponses;
    }

    isValid () {
        return this.valid;
    }

    hasChanges (): boolean {
        console.log(!isEqual(this.itemsOriginal, this.items));
        return !isEqual(this.itemsOriginal, this.items);
    }

    categoryCorrectionManagers () {
        return this.category?.correctionManagers?.where(x => x.tenantUser != null && x.tenantUser.userId != null).select(x => x.tenantUser!).toArray() ?? [];
    }

    findingManagersChanged (finding: FindingModel, e: string[]) {
        const findingManagersToDeleted = finding.findingManagers?.where(x => !e.any(n => n == x.tenantUserId)).toArray() ?? [];
        for (const item of findingManagersToDeleted) {
            finding.findingManagers!.splice(finding.findingManagers!.indexOf(item), 1);
        }
        const findingManagersToAdd = e.where(x => !finding.findingManagers!.any(n => n.tenantUserId == x)).toArray() ?? [];
        for (const item of findingManagersToAdd) {
            finding.findingManagers!.push({
                id: Guid.EmptyGuid(),
                findingId: finding.id,
                tenantUserId: item
            } as FindingManagerModel);
        }
    }

    getFindingManagersValue(finding: FindingModel) {
        const items = finding?.findingManagers?.select(x => x.tenantUserId!).toArray() ?? [];
        console.log(items);
        return items;
    }

}
