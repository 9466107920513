import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';
import Measures from './pages/Measures.vue';

export default [
    {
        path: '/:tenantId/measures',
        name: 'Measures',
        component: Measures,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.Measures ],
            checkLicence: true,
            title: "menu.solvedIssuesLong"
        }
    }
] as RouteConfig[];