



































































































































































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import {findingsStore} from '@/libs/findings/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CategoryModel, FindingModel, ImplementationStateEnum, MeasureModel, MeasureTypeEnum, PermissionTypeEnum, PlanItemModel, PlanItemStateEnum, QuestionModel, ResponseModel, TenantUserModel } from '@/libs/Api';
import WorkTaskFindingItemMeasure from '../components/WorkTaskFindingItemMeasure.vue';
import { cloneDeep } from 'lodash';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import WorkTaskDialogFiles from '../components/WorkTaskDialogFiles.vue';

@Component({
    components: {
        WorkTaskFindingItemMeasure,
        WorkTaskDialogFiles
    }
})
export default class WorkTaskFindingItem extends Page {

    /* Component props */
    @PropSync("finding") findingItem!: FindingModel;
    @Prop() planItem!: PlanItemModel;
    @Prop() category!: CategoryModel;

    /* Component state variables */
    dialog = false;
    valid = true;
    saving = false;
    hasChanges = true;
    dialogValid = true;
    dialogModel: FindingModel | null = null;
    
    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Ref */
    @Ref() dialogForm!: any;

    get item(): FindingModel {
        return {...this.findingItem};
    }

    get response(): ResponseModel {
        return this.planItem.responses!.first(x => x.id == this.item.responseId);
    }

    get isReadonly () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        const hasPersmissionToEdit = permissions.contains(PermissionTypeEnum.Findings);
        let canEditFinding = hasPersmissionToEdit && this.category.correctionManagers?.any(x => x.tenantUserId == this.tenantUser?.id);
        if (canEditFinding) {
            if (this.item.useDefinedManagers == true) {
                if (!this.item.findingManagers?.any(x => x.tenantUserId == this.tenantUser?.id)) {
                    canEditFinding = false;
                }
            }
        }
		//return (!canEditFinding || this.item.measures!.any(x => x.implementationState != ImplementationStateEnum.New) || this.planItem.planItemState == PlanItemStateEnum.ClosedWithoutFindings || this.planItem.planItemState == PlanItemStateEnum.Closed) && !permissions.any(x => x == PermissionTypeEnum.MistakeCorrection);
		return !canEditFinding || this.item.measures!.any(x => x.implementationState != ImplementationStateEnum.New) || this.planItem.planItemState == PlanItemStateEnum.ClosedWithoutFindings || this.planItem.planItemState == PlanItemStateEnum.Closed;
    }

    /* Action functions */
    
    openDialog () {
        this.dialogModel = cloneDeep(this.item);
        if (this.dialogModel.measures!.length == 0) {
            this.addMeasure();
        }
        this.dialogValid = true;
        this.dialog = true;
    }

    closeDialog () {
        this.dialog = false;
    }
    
    save () {
        if (this.dialogForm.validate()) {
            this.saving = true;
            const dialogModel = cloneDeep(this.dialogModel);
            dialogModel!.measures!.forEach(x => {
                x.sequenceIndex = dialogModel!.measures!.indexOf(x) + 1;
            });
            findingsStore.dispatch(CrudAction.Update, { item: dialogModel });
            this.subscribe(findingsStore, CrudReponse.Update).then((e) => {
                this.saving = false;
                this.$emit("planItemChanged");
                this.closeDialog();
            }).catch((e) => {
                this.saving = false;
                console.log(e);
            });
        }
    }

    addMeasure () {
        this.dialogModel?.measures?.push({
            implementationState: ImplementationStateEnum.New,
            measureType: this.category.useMeasureType ? MeasureTypeEnum.Corrective : null,
            implementationDescription: null,
            measureFiles: []
        } as MeasureModel);
    }

}
