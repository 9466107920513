



















































import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CategoryModel, FindingModel, MeasureModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import WorkTaskMeasureItem from '../components/WorkTaskMeasureItem.vue';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { measuresStore } from '@/libs/measures/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { categoriesStore } from '@/libs/categories/+state/store';
import { findingsStore } from '@/libs/findings/+state/store';

@Component({
    components: {
        WorkTaskMeasureItem
    }
})
export default class WorkTaskMeasureTab extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;
    
    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    
    /* Model variables */
    planItem: PlanItemModel | null = null;
    category: CategoryModel | null = null;
    findings: FindingModel[] = [];

    /* Component state variables */
    isLoading = false;

    /* Getters */
    
    get mineMeasures(): MeasureModel[] {
        const measures = [...measuresStore.useGetter(CrudGetter.Data)] as MeasureModel[]
        return measures.where(x => x.implementerUserId == this.tenantUser.id).orderBy(x => x.finding?.response?.sequenceIndex).thenBy(x => x.finding?.sequenceIndex).thenBy(x => x.sequenceIndex).toArray();
    }

    get otherMeasures(): MeasureModel[] {
        const measures = [...measuresStore.useGetter(CrudGetter.Data)] as MeasureModel[]
        return measures.where(x => x.implementerUserId != this.tenantUser.id).orderBy(x => x.finding?.response?.sequenceIndex).thenBy(x => x.finding?.sequenceIndex).thenBy(x => x.sequenceIndex).toArray();
    }

    /* Mounted */
    mounted() {
        this.isLoading = true;
        Promise.all([
            this.loadMeasures(),
            this.loadFindings(),
            this.loadPlanItem()
        ]).then(() => {
            this.loadCategory(this.planItem!.audit!.categoryId!).then(() => {
                this.isLoading = false;
            }).catch((e) => {
               this.isLoading = false; 
               console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        })
    }

    /* Action functions */

    loadMeasures(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            measuresStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<MeasureModel>(undefined, [
                { field: "finding.response.planItemId", op: "eq", comparand: this.id }
            ]));
            this.subscribe(measuresStore, CrudReponse.GetAll).then((e: MeasureModel[]) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadFindings(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            findingsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<FindingModel>(undefined, [
                { field: "response.planItemId", op: "eq", comparand: this.id }
            ]));
            this.subscribe(findingsStore, CrudReponse.GetAll).then((e: FindingModel[]) => {
                this.findings = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadPlanItem(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            planItemsStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(planItemsStore, CrudReponse.Get).then((e: PlanItemModel) => {
                this.planItem = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    getFinding(id: string) {
        return this.findings.firstOrDefault(x => x.id == id);
    }

    planItemChanged () {
        this.$emit("planItemChanged");
    }

}
