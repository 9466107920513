


























































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { authStore } from '@/libs/auth/+store/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { ApplicationUserModel, PermissionTypeEnum, TenantModel } from '@/libs/Api';
import { tenantsStore } from '@/libs/tenants/+state/store';
import {AuthenticationType} from "@/libs/auth/models/auth-type";
import {PublicClientApplication} from "@azure/msal-browser";
Vue.use(TooltipPlugin);

@Component({})
export default class SidebarMenu extends Vue {

    enableDock = true;
    dockSize = '72px'
    width = '220px';
    position ='Left';
    fields = { tooltip: 'text' };
    linkGroupId: string | null = null;
    mobileNavCollapsed = false;
    tenantMenu = false;
    selectedTenant: string | null = null;

    @Prop() tenantId!: string;
    
    get dataList(): Array<any> {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        if (tenantId && tenantId.length > 0) {
            return [
                { text: this.$i18n.t("menu.dashboard"), to: { name: 'Dashboard', params: { tenantId: tenantId } }, icon: "dashboard.svg", permissions: [ PermissionTypeEnum.PerformAudit ] },
                { text: this.$i18n.t("menu.audit"), to: { name: 'Audits', params: { tenantId: tenantId } }, icon: "audits.svg", permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit, PermissionTypeEnum.PerformAudit ] },
                { text: this.$i18n.t("menu.tasks"), to: { name: 'WorkTasks', params: { tenantId: tenantId } }, icon: "calendar.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan ] },
                { text: this.$i18n.t("menu.responses"), to: { name: 'Responses', params: { tenantId: tenantId } }, icon: "responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response, PermissionTypeEnum.MistakeCorrection ] },
                { text: this.$i18n.t("menu.unfinishedResponses"), to: { name: 'Unanswered', params: { tenantId: tenantId } }, icon: "unanswered-responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response ] },
                { text: this.$i18n.t("menu.issues"), to: { name: 'Issues', params: { tenantId: tenantId } }, icon: "issues.svg", permissions: [ PermissionTypeEnum.Findings ] },
                { text: this.$i18n.t("menu.solvedIssues"), to: { name: 'Measures', params: { tenantId: tenantId } }, icon: "measure.svg", permissions: [ PermissionTypeEnum.Measures ] },
                { text: this.$i18n.t("menu.verification"), to: { name: 'Verification', params: { tenantId: tenantId } }, icon: "resources.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.MeasureVerification ] },
                { text: this.$i18n.t("menu.review"), to: { name: 'Review', params: { tenantId: tenantId } }, icon: "tasks.svg", permissions: [ PermissionTypeEnum.PlanItemReview ] },
                { text: this.$i18n.t("menu.reports"), to: { name: 'ReportByQuestion', params: { tenantId: tenantId } }, icon: "reports.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ] }
            ]
        }
        else {
            return [];
        }
    }

    get userInfo(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get tenants() : Array<TenantModel> {
        return tenantsStore.useGetter(CrudGetter.Data);
    }

    @Watch("$route")
    routeChanged () {
        this.detectTenantMenu();
        this.mobileNavCollapsed = false;
    }

    @Watch("tenantId")
    tenantIdChanged (v: string, p: string) {
        if (v != null && v !== p && v != this.$route.params.tenantId) {
            this.selectedTenant = v;
        }
    }

    @Watch("selectedTenant")
    selectedTenantChanged (v: string, p: string) {
        if (v != null && v !== p && v != this.$route.params.tenantId) {
            authStore.dispatch(AuthAction.SetTenant, v);
        }
    }

    mounted () {
        this.detectTenantMenu();
        if (this.tenantId)
            this.selectedTenant = this.tenantId;
    }

    private signout () {
        debugger;
        authStore.dispatch(AuthAction.SignOut);
        const authenticationType = authStore.useGetter(AuthGetter.GetAuthenticationType) as AuthenticationType;
        if (authenticationType == AuthenticationType.AzureActiveDirectory) {
            const azureTenantId = authStore.useGetter(AuthGetter.GetAzureTenantId);
            const azureAppId = authStore.useGetter(AuthGetter.GetAzureAppId);
            const msalConfig = {
                auth: {
                    clientId: azureAppId,
                    authority: 'https://login.microsoftonline.com/' + azureTenantId
                }
            };
            const msalInstance = new PublicClientApplication(msalConfig);
            msalInstance.handleRedirectPromise();
            msalInstance.logoutPopup();
        }
    }

    private detectTenantMenu () {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        //this.tenantMenu = this.$router.currentRoute.meta?.tenant != false;
        this.tenantMenu = tenantId && tenantId.length > 0;
    }

    hasPermissions (permissionTypes: PermissionTypeEnum[]) {
        if (!permissionTypes)
            return true;
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.any(x => permissionTypes.any(p => p == x));
    }

    private toUserSettings () {
        this.$router.push({ name: "UserSettings" });
    }

}
