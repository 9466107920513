



















































































import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import WorkTaskDialog from '@/libs/work-tasks/components/WorkTaskDialog.vue';
import { responsesStore } from '@/libs/responses/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload, createGrouppedQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { Api, ConditionalQueryOperator, ConvertFunctionEnum, GroupByDefinition, GroupByProjectionDefinition, PlanItemModel, PredicateDefinition, ProjectionAggregationEnum, QueryModel, QueryOperator, ResponseModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';

@Component({
    components: {
        WorkTaskDialog
    }
})
export default class ReportByQuestion extends Page {

    categoryId: string | null = null;
    fromDate: string | null = null;
    toDate: string | null = null;
    tenantUser: Array<string> | null = null;
    department: Array<string> | null = null;
    position: Array<string> | null = null;
    audit: Array<string> | null = null;
    userGroup: Array<string> | null = null;
    mobileFiltersShow = true;

    tab: string | null = "absolute;"

    get downloading () {
        return planItemsStore.useGetter(CrudGetter.Downloading);
    }

    get chartOptions() {
        const tab = this.tab;
        let grouppedData = responsesStore.useGetter(CrudGetter.GrouppedData) as any[];
        grouppedData = grouppedData.orderBy(x => x.key.code).toArray();
        return {
            chart: {
                id: 'vuechart'
            },
            xaxis: {
                categories: grouppedData.select(x => x.key.code).toArray()
            },
            yaxis: {
                tickAmount: 1,
                decimalsInFloat: 0,
                labels: {
                    formatter: function (value: number) {
                        return tab == "absolute" ? value : (value.toFixed(2) + "%");
                    }
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: "10%"
                }
            },
            colors: [ "#c71e1e" ],
            dataLabels: {
                enabled: false
            }
        };
    }

    get chartSeries() {
        let grouppedData = responsesStore.useGetter(CrudGetter.GrouppedData) as any[];
        grouppedData = grouppedData.orderBy(x => x.key.code).toArray();
        if (this.tab == "absolute") {
            return [{
                name: this.$i18n.t("reports.countNok"),
                data: grouppedData.select(x => x.isNokCount).toArray()
            }];
        }
        else {
            return [{
                name: this.$i18n.t("reports.percentNok"),
                data: grouppedData.select(x => x.count > 0 ? x.isNokCount / x.count * 100 : 0).toArray()
            }];
        }
    }

    created () {

        this.tab = this.getQuery("tab") ?? null;
        this.categoryId = this.getQuery("categoryId") ?? null;
        this.fromDate = this.getQuery("fromDate") ?? null;
        this.toDate = this.getQuery("toDate") ?? null;
        // this.tenantUserId = this.getQuery("tenantUserId") ?? null;
        // this.departmentId = this.getQuery("departmentId") ?? null;
        // this.positionId = this.getQuery("positionId") ?? null;
        // this.auditId = this.getQuery("auditId") ?? null;
        // this.userGroupId = this.getQuery("userGroupId") ?? null;

    }

    getPredicates () {
        const filters: CrudQueryPredicate[] = [
            { field: "ResponseLock", op: QueryOperator.Eq, comparand: true }
        ];
        let auditFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let userGroupFilters: CrudQueryPredicate[] = [];
        if (this.categoryId)
            filters.push({ field: "Audit.CategoryId", op: QueryOperator.Eq, comparand: this.categoryId });
        if (this.fromDate)
            filters.push({ field: "Time", op: QueryOperator.Gte, comparand: this.fromDate });
        if (this.toDate)
            filters.push({ field: "Time", op: QueryOperator.Lte, comparand: this.toDate });
        if (this.tenantUser && this.tenantUser.length > 0) {
            this.tenantUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "PlanItem.TenantUserId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.department && this.department.length > 0) {
            this.department.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "PlanItem.TenantUser.DepartmentId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.position && this.position.length > 0) {
            this.position.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "PlanItem.TenantUser.PositionId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.audit && this.audit.length > 0) {
            this.audit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "AuditId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.userGroup && this.userGroup.length > 0) {
            this.userGroup.forEach((e: string) => {
                if (e) {
                    userGroupFilters.push({
                        field: "AuditTiming.GroupId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (auditFilters.length > 0) {
            filters.push({
                field: "AuditId",
                op: QueryOperator.Wrap,
                comparand: auditFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUserId",
                op: QueryOperator.Wrap,
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUser.DepartmentId",
                op: QueryOperator.Wrap,
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUser.PositionId",
                op: QueryOperator.Wrap,
                comparand: positionFilters
            });
        }
        if (userGroupFilters.length > 0) {
            filters.push({
                field: "AuditTiming.GroupId",
                op: QueryOperator.Wrap,
                comparand: userGroupFilters
            });
        }
        return filters;
    }

    downloadData() {
        planItemsStore.dispatch(CrudAction.Download, createCrudQueryPayload<PlanItemModel>([{field: "Time", index: 1, order: "asc"}], this.getPredicates()));
    }

    saveQuery () {

        if (this.tab)
            this.setQuery("tab", this.tab);
        else
            this.setQuery("tab", null);

        if (this.categoryId)
            this.setQuery("categoryId", this.categoryId);
        else
            this.setQuery("categoryId", null);

        if (this.fromDate)
            this.setQuery("fromDate", this.fromDate);
        else
            this.setQuery("fromDate", null);

        if (this.toDate)
            this.setQuery("toDate", this.toDate);
        else
            this.setQuery("toDate", null);

        // if (this.tenantUserId)
        //     this.setQuery("tenantUserId", this.tenantUserId);
        // else
        //     this.setQuery("tenantUserId", null);

        // if (this.departmentId)
        //     this.setQuery("departmentId", this.departmentId);
        // else
        //     this.setQuery("departmentId", null);

        // if (this.positionId)
        //     this.setQuery("positionId", this.positionId);
        // else
        //     this.setQuery("positionId", null);

        // if (this.auditId)
        //     this.setQuery("auditId", this.auditId);
        // else
        //     this.setQuery("auditId", null);

        // if (this.userGroupId)
        //     this.setQuery("userGroupId", this.userGroupId);
        // else
        //     this.setQuery("userGroupId", null);

    }

}
