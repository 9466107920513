







































































import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Constants } from '@/constants';
import { ApplicationUserModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import { tenantUsersStore } from '@/libs/tenants/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import WorkTaskDialog from '@/libs/work-tasks/components/WorkTaskDialog.vue';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

moment.locale("cs");

@Component({
    components: {
        WorkTaskDialog
    }
})
export default class DashboardMainPanel extends Page {

    today = new Date();
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail);
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    get donePlanItemsCount(): number {
        return planItemsStore.useGetter(CrudGetter.Count, "DonePlanItemsCount") ?? 0;
    }

    get waitingPlanItemsCount(): number {
        return planItemsStore.useGetter(CrudGetter.Count, "WaitingPlanItemsCount") ?? 0;
    }

    get notFinishedItems(): PlanItemModel[] {
        return planItemsStore.useGetter(CrudGetter.DataBasic, "NotFinished");
    }

    @Ref() dialog!: WorkTaskDialog;

    mounted () {

        this.loadTenantUser();
        
        this.reloadData();
        
    }

    reloadData () {
        
        this.loadDonePlanItemsCount();
        this.loadWaitingPlanItemsCount();

        this.loadNotFinished();
        
    }

    loadTenantUser () {
        tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>(undefined, [
            { field: "id", op: "eq", comparand: this.tenantUser.id! }
        ], undefined, false, "TenantUser"))
    }

    loadDonePlanItemsCount () {
        planItemsStore.dispatch(CrudAction.GetCount, createAggregationQueryPayload([
            { field: "time", op: "gte", comparand: moment().add(-1, "year").startOf("day").toDate() },
            { field: "time", op: "lte", comparand: moment().endOf("day").toDate() },
            { field: "isAnswered", op: "eq", comparand: true },
            { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! }
        ], "", "DonePlanItemsCount"));
    }

    loadWaitingPlanItemsCount () {
        planItemsStore.dispatch(CrudAction.GetCount, createAggregationQueryPayload([
            { field: "time", op: "gte", comparand: moment().add(-1, "year").startOf("day").toDate() },
            { field: "time", op: "lte", comparand: moment().add(-1, "days").endOf("day").toDate() },
            { field: "isAnswered", op: "eq", comparand: false },
            { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! }
        ], "", "WaitingPlanItemsCount"));
    }

    loadNotFinished() {
        planItemsStore.dispatch(CrudAction.GetAllBasic, createCrudQueryPayload<PlanItemModel>([
            { field: "time", index: 1, order: "desc" },
            { field: "audit.name", index: 2, order: "asc" }
        ], [
            { 
                field: "id", 
                op: "wrap", 
                comparand: [
                    { field: "time", op: "gte", comparand: moment().startOf("day").toDate() },
                    { field: "time", op: "lte", comparand: moment().endOf("day").toDate() },
                    { field: "isAnswered", op: "eq", comparand: false },
                    { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! }
                ],
                junction: "or"
            },
            { 
                field: "id", 
                op: "wrap", 
                comparand: [
                    { field: "time", op: "gte", comparand: moment().add(-1, "year").startOf("day").toDate() },
                    { field: "time", op: "lte", comparand: moment().endOf("day").toDate() },
                    { field: "isAnswered", op: "eq", comparand: false },
                    { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! },
                    { field: "responseCount", op: "gt", comparand: 0 },
                ],
                junction: "or"
            },
           

        ], undefined, false, "NotFinished"));
    }

    open (item: PlanItemModel) {
        this.dialog.open(item.id!, item!.auditId!, item.time!);
    }

}

