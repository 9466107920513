import { runInContext } from 'lodash';
import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';

import WorkTasks from './pages/WorkTasks.vue';
import WorkTask from './pages/WorkTask.vue';
import WorkTaskAuditForm from './pages/WorkTaskAuditForm.vue';

import WorkTaskAuditTab from './pages/WorkTaskAuditTab.vue';
import WorkTaskFindingTab from './pages/WorkTaskFindingTab.vue';
import WorkTaskMeasureTab from './pages/WorkTaskMeasureTab.vue';
import WorkTaskVerifyTab from './pages/WorkTaskVerifyTab.vue';

export default [
    {
        path: '/:tenantId/work-tasks',
        name: 'WorkTasks',
        component: WorkTasks,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response ],
            checkLicence: true,
            title: "menu.tasks"
        }
    },
    {
        path: '/:tenantId/work-task/:id',
        name: 'WorkTask',
        component: WorkTask,
        props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response, PermissionTypeEnum.Findings, PermissionTypeEnum.Measures, PermissionTypeEnum.MeasureVerification, PermissionTypeEnum.MistakeCorrection ],
            checkLicence: true,
            title: "audits.auditDetail"
        },
        children: [
            {
                path: '/:tenantId/work-task/:id/measures',
                name: 'WorkTaskMeasureTab',
                component: WorkTaskMeasureTab,
                props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
                meta: {
                    permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response, PermissionTypeEnum.Findings, PermissionTypeEnum.Measures, PermissionTypeEnum.MeasureVerification, PermissionTypeEnum.MistakeCorrection ],
                    checkLicence: true,
                    title: "audits.auditDetail"
                }
            },
            {
                path: '/:tenantId/work-task/:id/verification',
                name: 'WorkTaskVerifyTab',
                component: WorkTaskVerifyTab,
                props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
                meta: {
                    permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response, PermissionTypeEnum.Findings, PermissionTypeEnum.Measures, PermissionTypeEnum.MeasureVerification, PermissionTypeEnum.MistakeCorrection ],
                    checkLicence: true,
                    title: "audits.auditDetail"
                }
            },
            {
                path: '/:tenantId/work-task/:id/findings',
                name: 'WorkTaskFindingTab',
                component: WorkTaskFindingTab,
                props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
                meta: {
                    permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response, PermissionTypeEnum.Findings, PermissionTypeEnum.Measures, PermissionTypeEnum.MeasureVerification, PermissionTypeEnum.MistakeCorrection ],
                    checkLicence: true,
                    title: "audits.auditDetail"
                }
            },
            {
                path: '/:tenantId/work-task/:id/audit',
                name: 'WorkTaskAuditTab',
                component: WorkTaskAuditTab,
                props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
                meta: {
                    permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan, PermissionTypeEnum.Response, PermissionTypeEnum.Findings, PermissionTypeEnum.Measures, PermissionTypeEnum.MeasureVerification, PermissionTypeEnum.MistakeCorrection ],
                    checkLicence: true,
                    title: "audits.auditDetail"
                }
            }
        ]
    },
    {
        path: '/:tenantId/work-task-form/:id',
        name: 'WorkTaskAuditFormExisting',
        component: WorkTaskAuditForm,
        props: (route) => ({tenantId: route.params.tenantId, id: route.params.id}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit ],
            checkLicence: true,
            title: "audits.performAudit",
            hideMobileHeader: true
        }
    },
    {
        path: '/:tenantId/new-work-task/:auditId',
        name: 'WorkTaskAuditForm',
        component: WorkTaskAuditForm,
        props: (route) => ({tenantId: route.params.tenantId, auditId: route.params.auditId}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit ],
            checkLicence: true,
            title: "audits.performAudit",
            hideMobileHeader: true
        }
    }
] as RouteConfig[];
