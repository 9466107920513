











































































































































































































































































































































import { ApplicationUserModel, AuditExplanationTypeEnum, AuditModel, CategoryModel, EscalationTypeEnum, FileModel, ImplementationStateEnum, PermissionTypeEnum, PlanItemModel, QuestionFileModel, QuestionGroupModel, ResponseFileModel, ResponseFileType, ResponseModel, ResponseTypeEnum, ResponseValueModel, TenantModel, TenantUserModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { questionGroupsStore } from '@/libs/question-groups/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { QuestionGroupResponse } from '../model/QuestionGroupResponse';
import { Guid } from '@/libs/common/functions/guid';
import moment from 'moment';
import { auditsStore } from '@/libs/audits/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { getAccessToken } from 'axios-jwt';
import axios from 'axios';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { cloneDeep, difference, isEqual } from 'lodash';
import WorkTaskDialogFiles from './WorkTaskDialogFiles.vue';
import QuestionFileItem from '@/libs/audits/components/QuestionFileItem.vue';
import QuestionFileDetail from '@/libs/audits/components/QuestionFileDetail.vue';

@Component({
    components: {
        WorkTaskDialogFiles, QuestionFileItem, QuestionFileDetail
    }
})
export default class WorkTaskDialog extends Page {

    id!: string;
    auditId!: string;
    day!: string;
    dialog = false;
    valid = true;
    saving = false;
    questionGroups: Array<QuestionGroupModel> = [];
    items: Array<QuestionGroupResponse> = [];
    itemsOriginal: Array<QuestionGroupResponse> = [];
    searchValue = "";
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    finishedPercent = 0;
    showAll = false;
    planItem: PlanItemModel | null = null;
    planItemOriginal: PlanItemModel | null = null;
    audit: AuditModel | null = null;
    auditPerformed = false;
    newId = Guid.EmptyGuid();
    hasChanges = false;
    responseLockDialog = false;
    category: CategoryModel | undefined = undefined;
    showResponseValue: string | null = null;
    currentQuestionFile: any = {
        questionGroupIndex: 0,
        questionIndex: 0,
        fileIndex: 0,
        canPrev: false,
        canNext: false,
        file: null
    };

    @Ref() questionFileDetail!: QuestionFileDetail;

    get canShowWaitingQuestionOption () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == false);
        //return ((this.planItem != null && this.planItem.userId == this.applicationUser.id) || permissions.contains(PermissionTypeEnum.MistakeCorrection)) && this.planItem != null && this.planItem.responseLock == false;
    }

    get canEditWorkplace () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditQuestions () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditFiles () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canShowCorrectiveMeasures () {
        return (this.planItem != null && this.planItem.responseLock == true);
    }

    get canEditCorrectiveMeasurements () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && this.planItem.fullLock == false && permissions.contains(PermissionTypeEnum.Findings)) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    canEditImplementationOfCorrectiveMeasurements (response: ResponseModel) {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && this.planItem.fullLock == false && permissions.contains(PermissionTypeEnum.Measures) && response.implementerUserId == this.tenantUser.id) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }
    
    get isEnabledCommentOnOkResponse() {
        const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
        return tenant.isEnabledCommentOnOkResponse == true;
    }

    canEditAnyImplementationOfCorrectiveMeasurements () {
        if (this.planItem == null || this.planItem.responses == null)
            return false;
        return this.planItem.responses.any(x => this.canEditImplementationOfCorrectiveMeasurements(x));
    }

    get requiredRules() {
        return [
            (v: any) => !!v || "Pole je vyžadováno"
        ];
    }

    get loading(): boolean {
        const planItems = planItemsStore.useGetter(CrudGetter.Pending);
        const questionGroups = questionGroupsStore.useGetter(CrudGetter.Pending);
        if (planItems || questionGroups)
            return true;
        else
            return false;
    }

    get unansweredQuestionsCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if ((!response.scoreValue && response.scoreValue != 0) && !response.okValue)
                    result++;
            });
        });
        return result;
    }

    get missingDescriptionsCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.description == '')
                    result++;
            });
        });
        return result;
    }

    get missingImmediateMeasuresCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.immediateMeasures == '')
                    result++;
            });
        });
        return result;
    }

    get missingEscalationDescriptionCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.escalationDescription == '')
                    result++;
            });
        });
        return result;
    }

    get okResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk && response.okValue == 1)
                    result++;
            });
        });
        return result;
    }

    get nokResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk == false)
                    result++;
            });
        });
        return result;
    }

    get okWithWarningResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.isOk && response.okValue == 2)
                    result++;
            });
        });
        return result;
    }

    get scoreZeroResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 0)
                    result++;
            });
        });
        return result;
    }

    get scoreOneResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 1)
                    result++;
            });
        });
        return result;
    }

    get scoreTwoResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 2)
                    result++;
            });
        });
        return result;
    }

    get scoreThreeResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 3)
                    result++;
            });
        });
        return result;
    }

    get scoreFourResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 4)
                    result++;
            });
        });
        return result;
    }

    get scoreFiveResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 5)
                    result++;
            });
        });
        return result;
    }

    get scoreSixResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 6)
                    result++;
            });
        });
        return result;
    }

    get scoreEightResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 8)
                    result++;
            });
        });
        return result;
    }

    get scoreTenResponseCount(): number {
        let result = 0;
        this.items.forEach((item: QuestionGroupResponse) => {
            item.responses?.forEach((response: ResponseModel) => {
                if (response.scoreValue == 10)
                    result++;
            });
        });
        return result;
    }

    @Watch("planItem", { deep: true })
    @Watch("items", { deep: true })
    planItemChanged () {
        const itemsDiff = difference(this.itemsOriginal, this.items);
        this.hasChanges = !isEqual(this.planItemOriginal, this.planItem) || !isEqual(this.itemsOriginal, this.items);
    }

    @Ref() form!: any;

    open (id: string, auditId: string, day: string, showAll = false) {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        this.dialog = true;
        this.id = id;
        this.auditId = auditId;
        this.day = day;
        this.showAll = showAll && !permissions.contains(PermissionTypeEnum.MistakeCorrection);
        this.load();
    }

    create (auditId: string) {
        this.dialog = true;
        this.id = Guid.EmptyGuid();
        this.auditId = auditId;
        this.day = moment().format("YYYY-MM-DDT00:00:00");
        this.showAll = false;
        this.load();
    }

    close () {
        this.dialog = false;
    }

    showResponseValues (value: string) {
        if (value != this.showResponseValue)
            this.showResponseValue = value;
        else
            this.showResponseValue = null;
    }

    private load () {
        return new Promise((resolve, reject) => {
            this.loadPlanItem().then((e: PlanItemModel) => {
                if (e.auditId)
                    this.auditId = e.auditId;
                this.loadAudit().then((e: AuditModel) => {
                    this.loadQuestionGroups().then((e) => {
                        resolve(e);
                    }).catch((e: any) => {
                        reject(e);
                    });
                }).catch((e: any) => {
                    reject(e);
                });
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private loadPlanItem() : any {
        return new Promise((resolve, reject) => {
            if (this.id == Guid.EmptyGuid()) {
                auditsStore.dispatch(CrudAction.Get, { id: this.auditId });
                this.subscribe(auditsStore, CrudReponse.Get).then((e: AuditModel) => {
                    this.planItem = {
                        id: Guid.EmptyGuid(),
                        userId: this.applicationUser.id,
                        auditId: this.auditId,
                        audit: e,
                        time: moment().format("YYYY-MM-DDT00:00:00"),
                        responses: [],
                        responseLock: false,
                        fullLock: false
                    } as PlanItemModel;
                    this.planItemOriginal = cloneDeep(this.planItem);
                    this.category = e.category;
                    this.hasChanges = false;
                    resolve(this.planItem);
                }).catch((e) => {
                    reject(e);
                })
            }
            else {
                planItemsStore.dispatch(CrudAction.Get, { id: this.id });
                this.subscribe(planItemsStore, CrudReponse.Get).then((e: PlanItemModel) => {
                    this.planItem = e;
                    this.planItemOriginal = cloneDeep(this.planItem);
                    this.category = e.audit!.category;
                    this.hasChanges = false;
                    this.showAll = this.showAll && this.planItem && this.planItem.tenantUserId != this.tenantUser.id;
                    resolve(e);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        });
    }

    private loadAudit (): Promise<any> {
        return new Promise((resolve, reject) => {
            auditsStore.dispatch(CrudAction.Get, { id: this.auditId!, key: this.auditId! });
            this.subscribe(auditsStore, CrudReponse.Get, this.auditId!).then((e: AuditModel) => {
                this.audit = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private loadQuestionGroups (): Promise<any> {
        return new Promise((resolve, reject) => {
            questionGroupsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<QuestionGroupModel>({ field: "position", index: 1, order: "asc" }, [
                {field: "auditId", op: "eq", comparand: this.auditId}
            ], undefined, false, "WorkTaskDialog"));
            this.subscribe(questionGroupsStore, CrudReponse.GetAll).then((e: QuestionGroupModel[]) => {
                e.forEach(g => {
                    if (g.questions)
                        g.questions.sort((a, b) => a.position! - b.position!);
                });
                this.questionGroups = e;
                this.make();
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private make () {
        this.items = this.questionGroups.where(q => (this.planItem && this.planItem.responses && this.planItem.responses.any(x => x.question != null && x.question.questionGroupId == q.id)) || (this.planItem && !this.planItem.isAnswered) || !this.showAll).toArray().map((e) => {
            const n = {
                id: e.id,
                auditId: e.auditId,
                name: e.name,
                position: e.position,
                responses: e.questions ? e.questions.where(q => (this.planItem && ((this.planItem.responses && this.planItem.responses.any(x => x.questionId == q.id)) || !this.planItem.isAnswered)) || !this.showAll).toArray().map((q) => {
                    if (this.planItem && this.planItem.responses) {
                        var response = this.planItem.responses.firstOrDefault(x => x.questionId == q.id) as any;
                        var responseObject = this.planItem.responses.firstOrDefault(x => x.questionId == q.id) as any;
                        if (responseObject != null) {
                            responseObject.auditFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.Response).toArray();
                            responseObject.correctiveMeasureFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.CorrectiveMeasure).toArray();
                            responseObject.correctiveMeasureImplementationFiles = response.responseFiles.where((f: ResponseFileModel) => f.type == ResponseFileType.CorrectiveMeasureImplementation).toArray();
                            return responseObject;
                        }
                        return {
                            id: Guid.EmptyGuid(),
                            isOk: null,
                            questionId: q.id,
                            question: q,
                            value: "",
                            auditFiles: [],
                            correctiveMeasureFiles: [],
                            correctiveMeasureImplementationFiles: [],
                            immediateMeasures: null,
                            escalationDescription: null,
                            description: null
                        } as ResponseModel;
                    }
                    else {
                        return null;
                    }
                }).where(x => x != null).toArray() : []
            } as QuestionGroupResponse;
            return n;
        }) as Array<QuestionGroupResponse>;
        this.itemsOriginal = cloneDeep(this.items);
    }

    private save (showResponseLock: true) {
        this.responseLockDialog = false;
        if (this.form.validate()) {
            let notFinishedResponses = [];
            for (const item of this.items) {
                if (item.responses)
                    item.responses.forEach((e: any) => {
                        if (e.okValue == null && e.scoreValue == null) {
                            notFinishedResponses.push(e);
                        }
                    });
            }
            if (this.canEditQuestions && !this.planItem!.responseLock && this.planItem!.isRequestedResponseLock != true && showResponseLock && notFinishedResponses.length == 0) {
                this.responseLockDialog = true;
            }
            else {
                if (notFinishedResponses.length > 0) {
                    this.planItem!.isRequestedResponseLock = false;
                }
                this.saving = true;
                const responses: Array<ResponseModel> = [];
                for (const item of this.items) {
                    if (item.responses)
                        item.responses.forEach((e: any) => {
                            e.responseFiles = [];
                            e.auditFiles.forEach((f: any) => {
                                e.responseFiles.push(f);
                            });
                            e.correctiveMeasureFiles.forEach((f: any) => {
                                e.responseFiles.push(f);
                            });
                            e.correctiveMeasureImplementationFiles.forEach((f: any) => {
                                e.responseFiles.push(f);
                            });
                            if (e.isOk == true) {
                                e.escalationDescription = "";
                                e.implementerUserId = null;
                                e.implementationState = ImplementationStateEnum.None;
                                e.implementationTime = null;
                            }
                            if (!this.planItem!.responseLock) {
                                if (e.isOk == false || e.immediateMeasures != null) {
                                    e.hasDisagreement = true;
                                }
                            }
                            responses.push(e);
                        })
                }
                const planItem = {...this.planItem};
                planItem.responses = responses;
                if (this.id == Guid.EmptyGuid()) {
                    planItemsStore.dispatch(CrudAction.Create, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Create).then((e: PlanItemModel) => {
                        this.auditPerformed = true;
                        this.planItem = e;
                        this.planItemOriginal = cloneDeep(this.planItem);
                        this.make();
                        this.saving = false;
                        this.close();
                        this.$emit("saved");
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }
                else {
                    planItemsStore.dispatch(CrudAction.Update, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Update).then((e: PlanItemModel) => {
                        this.auditPerformed = true;
                        this.planItem = e;
                        this.planItemOriginal = cloneDeep(this.planItem);
                        this.make();
                        this.saving = false;
                        this.close();
                        this.$emit("saved");
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }
            }
        }
    }

    public responseChanged(response: ResponseModel) {
        let responseValue: ResponseValueModel | null = null;
        if (this.category!.responseType == ResponseTypeEnum.OkNok || this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.okValue) ?? null;
        }
        else {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.scoreValue) ?? null;
        }
        if (responseValue == null) {
            response.isOk = null;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.RequiredEscalation) {
            response.isOk = false;
            response.immediateMeasures = '';
            response.escalationDescription = '';
        }
        else if (responseValue?.escalationType == EscalationTypeEnum.OptionalEscalation) {
            response.isOk = true;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
        else {
            response.isOk = true;
            response.immediateMeasures = null;
            response.escalationDescription = null;
        }
    }

    public canEscalate (response: ResponseModel) {
        let responseValue: ResponseValueModel | null = null;
        if (this.category!.responseType == ResponseTypeEnum.OkNok || this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.okValue) ?? null;
        }
        else {
            responseValue = this.category!.responseValues?.firstOrDefault(x => x.value == response.scoreValue) ?? null;
        }
        return responseValue?.escalationType == EscalationTypeEnum.OptionalEscalation;
    }

    public escalate (response: ResponseModel) {
        response.isOk = false;
    }

    public cancelEscalation (response: ResponseModel) {
        response.isOk = true;
    }

    public plainToHtml (value: string) {
        return value?.replace("\n", "<br />");
    }

    private getExplanations(questionGroup: QuestionGroupModel) {
        let explanations: any[] = [];
        if (this.planItem!.audit!.explanationType == AuditExplanationTypeEnum.ByCategory) {
            explanations = this.planItem!.audit!.category!.responseValues!.map(x => {
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: x.explanationDescription
                }
            }).toArray();
        }
        else if (this.planItem!.audit!.explanationType == AuditExplanationTypeEnum.ByAudit) {
            explanations = this.planItem!.audit!.category!.responseValues!.map(x => {
                const auditExplanation = this.audit!.auditExplanations!.firstOrDefault(n => n.responseValue == x.value);
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: auditExplanation?.explanationDescription
                }
            }).toArray();
        }
        else if (this.planItem!.audit!.explanationType == AuditExplanationTypeEnum.ByQuestionGroup) {
            explanations = this.planItem!.audit!.category!.responseValues!.map(x => {
                const filteredQuestionGroup = this.questionGroups.first(n => n.id == questionGroup.id);
                const questionGroupExplanation = filteredQuestionGroup!.questionGroupExplanations?.firstOrDefault(n => n.responseValue == x.value);
                return {
                    value: x.value,
                    color: x.color,
                    explanationDescription: questionGroupExplanation?.explanationDescription
                }
            }).toArray();
        }
        if (!explanations.any(x => x.explanationDescription != null && x.explanationDescription.toString().length > 0)) {
            return [];
        }
        else {
            return explanations;
        }
    }

    private canShowExplanations(questionGroup: QuestionGroupModel) {
        
        if (this.audit?.explanationType == AuditExplanationTypeEnum.None || this.audit?.explanationType == null)
            return false;

        return this.getExplanations(questionGroup).length > 0;

    }

    showQuestionFileDetail (questionGroupIndex: number, questionIndex: number, fileIndex: number) {
        this.currentQuestionFile = {
            questionGroupIndex: questionGroupIndex,
            questionIndex: questionIndex,
            fileIndex: fileIndex,
            canPrev: fileIndex > 0 ? true : false,
            canNext: fileIndex + 1 < this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length ? true : false,
            file: this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles![fileIndex]
        };
        console.log(this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length);
        if (this.currentQuestionFile)
            this.questionFileDetail.open(this.currentQuestionFile);
    }

    prevQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex - 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex--;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = true;
        }
    }

    nextQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex + 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex++;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = this.currentQuestionFile.fileIndex + 1 < this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles!.length ? true : false;
        }
    }

}
