
































































import NotificationService from '@/libs/core/notifications/notification-service';
import {Component, Vue, Watch} from "vue-property-decorator";
import {DropDownButtonPlugin} from "@syncfusion/ej2-vue-splitbuttons";

import {authStore} from '@/libs/auth/+store/store';
import {tenantsStore, tenantUsersStore} from '@/libs/tenants/+state/store';
import {AuthAction, AuthGetter} from '@/libs/auth/models/auth-state';
import {CrudAction, CrudReponse} from './libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from './libs/core/+state/models/crud-query-payload';
import {
    ApplicationUserModel,
    CodebookModel,
    GroupModel,
    PermissionTypeEnum,
    RoleModel,
    TenantModel,
    TenantUserModel,
    WorkplaceModel
} from './libs/Api';
import {CrudGetter} from './libs/core/+state/models/crud-getter';
import {EventTypeEnum} from '@/libs/core/models/event-type-enum';

import {formatDate} from '@/libs/common/functions/format-date';
import {applicationUserStore} from './libs/user-settings/+state/store';
import Page from './Page.vue';
import {codebooksStore} from './libs/codebooks/+state/store';

import Sidebar from '@/libs/common/components/Sidebar.vue';

import {GlobalVariables} from '@/libs/core/global';
import {workplacesStore} from './libs/workplaces/+state/store';
import {rolesStore} from './libs/roles/+state/store';
import {groupsStore} from './libs/groups/+state/store';
import moment from 'moment';
import {AuthenticationType} from './libs/auth/models/auth-type';
import {PublicClientApplication} from "@azure/msal-browser";

Vue.use(DropDownButtonPlugin);

@Component({
  components: {
    Sidebar
  },
})
export default class App extends Page {

  loading = true;
  user: any = {};
  selectedTenant: string | null = null;
  locale = "cs";
  tenantMenu = false;
  pageTitle: string | undefined | null = null;
  showMobileHeader = true;
  
  //@Prop() tenantId!: string;

  get tenantId (): string | null {
    return authStore.useGetter(AuthGetter.GetTenant);
  }
  
  get isLoggedIn(): boolean {
    return authStore.useGetter(AuthGetter.GetIsLoggedIn);
  }

  get tenants() : Array<TenantModel> {
    return (tenantsStore.useGetter(CrudGetter.Data) as TenantModel[]).orderBy(x => x.name).toArray();
  }

  get tenant(): TenantModel {
    return tenantsStore.useGetter(CrudGetter.Detail);
  }

  get userInfo(): ApplicationUserModel {
    return applicationUserStore.useGetter(CrudGetter.Detail);
  }

  get tenantLoading() {
    return tenantsStore.useGetter(CrudGetter.Pending);
  }

  @Watch("tenant.isRequiredTwoFactor")
  isRequiredTwoFactorChanged() {
    this.detectTwoFactorRequirements();
  }

  created () {
    this.$i18n.locale = window.localStorage.language ?? "cs";
    this.locale = this.$i18n.locale;
    this.$vuetify.lang.current = this.locale;
    GlobalVariables.lang = this.locale;
  }

  @Watch("isLoggedIn")
  isLoggedInChanged (v: boolean) {
    if (!this.loading) {
      if (!v) {
        if (this.$route.name != "PasswordRecovery" && this.$route.name != "SignIn" && this.$route.name != "SignUp" && this.$route.name != "SignUpOrganization")
          this.$router.push({ name: "SignIn" });
      }
      else {
        this.connectSignalR();
        //this.loadUserData();
      }
    }
  }

  @Watch("tenantId")
  tenantIdChanged (v: string, p: string) {
    this.selectedTenant = v;
    //this.loading = true;
    //this.loadTenantStores();
    if (p != null && p != "" && v != null && v != "" && v != p) {
      const currentRoute = {...this.$router.currentRoute};
      currentRoute.params.tenantId = v;
      this.$router.replace({
        name: currentRoute.name!,
        params: currentRoute.params!,
        query: currentRoute.query!
      });
      if (p != null)
        window.location.reload();
    }
  }

  @Watch("selectedTenant")
  selectedTenantChanged (v: string, p: string) {
    if (p != null && p != "" && v != null && v != "" && v != this.$route.params.tenantId) {
      authStore.dispatch(AuthAction.SetTenant, v);
    }
  }

  @Watch("$route")
  routeChanged () {
    if (!this.loading) {
      this.detectTenantMenu();
      this.detectPermissions();
      this.detectSystemUser();
      this.detectLicence();
      this.detectTwoFactorRequirements();
      this.getPageTitle();
      this.setDocumentTitle();
      this.isMobileHeaderShown();
      if (this.tenantMenu && this.tenantId == null) {
        const tenants = tenantsStore.useGetter(CrudGetter.Data) as TenantModel[];
        if (tenants.length == 0) {
          this.$router.push({ name: "UserSettings" });
        }
        else {
          authStore.dispatch(AuthAction.SetTenant, tenants[0].id!);
        }
      }
    }
  }

  mounted() {
    this.detectTenantMenu();
    this.getPageTitle();
    this.setDocumentTitle();
    this.isMobileHeaderShown();
    const envKey = process.env.NODE_ENV != "production" ? ".".concat(process.env.NODE_ENV!) : "";
    fetch(process.env.BASE_URL + "config".concat(envKey).concat(".json")).then((result) => {
      result.json().then((jsonBody: any) => {
        (window as any).ApiService.baseUrl = jsonBody.ApiEndpoint;
        (window as any).SaasApiEndpoint = jsonBody.SaasApiEndpoint;
        const supportedAuthenticationTypes = jsonBody.Authentication;
        authStore.dispatch(AuthAction.SetIsSingleTenant, jsonBody.IsSingleTenant ?? false);
        authStore.dispatch(AuthAction.SetSupportedAuthenticationTypes, supportedAuthenticationTypes);
        authStore.dispatch(AuthAction.SetAuthenticationType, window.localStorage.AuthenticationType ?? supportedAuthenticationTypes.first());
        authStore.dispatch(AuthAction.SetAzureTenantId, jsonBody.AzureTenantId);
        authStore.dispatch(AuthAction.SetAzureAppId, jsonBody.AzureAppId);
        const self = this;
        authStore.dispatch(AuthAction.UserInfo);
        const subscription = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === authStore.getActionName(AuthAction.UserInfo)) {
            subscription();
            self.user = mutation.payload;
            (window as any).user = self.user;
            if (!_state.AUTH.isLoggedIn && self.$route.name !== "PasswordRecovery" && self.$route.name !== "SignIn" && self.$route.name !== "SignUp") {
              this.loading = false;
              self.$router.push({ name: "SignIn" });
            }
            else {
              this.loadUserData();
              this.connectSignalR();
            }
          }
        });
      });
    });
    (window as any).Journaly.subscribe((tenantId: string): Promise<string> => {
      tenantsStore.dispatch(CrudAction.Get, { id: tenantId })
      return new Promise((resolve) => resolve("opened"));
    }, EventTypeEnum.TenantChanged);
    (window as any).Journaly.subscribe((): Promise<string> => {
      this.loadUserData();
      return new Promise((resolve) => resolve("loadingUserData"));
    }, EventTypeEnum.SignedIn);
  }

  toggleClick() {
    (this.$refs.dockSidebar as any).toggle();
  }

  connectSignalR() {
    NotificationService.init();
  }

  signout () {
    authStore.dispatch(AuthAction.SignOut);
    const authenticationType = authStore.useGetter(AuthGetter.GetAuthenticationType) as AuthenticationType;
    if (authenticationType == AuthenticationType.AzureActiveDirectory) {
        const azureTenantId = authStore.useGetter(AuthGetter.GetAzureTenantId);
        const azureAppId = authStore.useGetter(AuthGetter.GetAzureAppId);
        const msalConfig = {
            auth: {
                clientId: azureAppId,
                authority: 'https://login.microsoftonline.com/' + azureTenantId
            }
        };
        const msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.handleRedirectPromise();
        msalInstance.logoutPopup();
    }
  }

  private detectTenantMenu () {
    this.tenantMenu = this.$router.currentRoute.meta?.tenant != false;
  }

  private detectPermissions () {
    if (this.$router.currentRoute.meta.permissions) {
      const neededPermissions = this.$router.currentRoute.meta.permissions as PermissionTypeEnum[];
      const userPermissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
      if (!userPermissions.any(x => x == PermissionTypeEnum.Admin) && !neededPermissions.any(x => userPermissions.contains(x))) {
        this.$router.push({ name: "Home", params: { tenantId: this.tenantId! } });
      }
    }
  }

  private detectSystemUser () {
    if (this.$router.currentRoute.meta?.systemUser == true) {
      const applicationUser = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
      if (!applicationUser?.systemUser) {
        this.$router.push({ name: "Home", params: { tenantId: this.tenantId! } });
      }
    }
  }

  private detectLicence () {
    if (this.$router.currentRoute.meta.checkLicence) {
      const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
      if (tenant) {
        const licenceToDate = tenant.licenceToDate ? moment(tenant.licenceToDate) : moment().add(-1, "days");
        if (licenceToDate.isBefore(moment())) {
          this.$router.push({ name: "BadLicence", params: { tenantId: this.tenantId! } });
          return;
        }
      }
      const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
      if (tenantUser) {
        if (!tenantUser.isActive) {
          this.$router.push({ name: "BadLicence", params: { tenantId: this.tenantId! } });
          return;
        }
      }
    }
  }

  private detectTwoFactorRequirements() {
    if (this.$router.currentRoute.meta.checkLicence) {
      const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
      const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
      if (tenant.isRequiredTwoFactor && !user.twoFactorEnabled && authStore.useGetter(AuthGetter.GetAuthenticationType) == AuthenticationType.Standard) {
        this.$router.push({ name: "MissingMfa", params: { tenantId: this.tenantId! } });
          return;
      }
    }
  }

  loadUserData () {
    applicationUserStore.dispatch(CrudAction.Get, {});
    this.subscribe(applicationUserStore, CrudReponse.Get).then((e: any) => {
      tenantsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantModel>({
        field: 'name',
        order: 'asc'
      }));
      this.subscribe(tenantsStore, CrudReponse.GetAll).then((e: TenantModel[]) => {
        this.detectSystemUser();
        if (e.length > 0) {
          this.loadCodebooks().then(() => {
            let tenantId = this.$route.params.tenantId;
            this.selectedTenant = tenantId;
            if (!tenantId && window.localStorage.CurrentTenant && e.any(x => x.id == window.localStorage.CurrentTenant)) {
              tenantId = window.localStorage.CurrentTenant;
              authStore.dispatch(AuthAction.SetTenant, tenantId);
              this.loadTenantStores();
              this.selectedTenant = tenantId;
            }
            else if (!tenantId && e.length > 0) {
              authStore.dispatch(AuthAction.SetTenant, e[0].id!);
              this.loadTenantStores(true);
              this.selectedTenant = e[0].id!;
            }
            else {
              authStore.dispatch(AuthAction.SetTenant, tenantId);
              this.loadTenantStores();
              this.selectedTenant = tenantId;
            }
          });
        }
        else {
          authStore.dispatch(AuthAction.SetTenant, "");
          this.$router.push({ name: "UserSettings" });
          this.loading = false;
        }
      });
    }).catch((e: any) => {
      this.loading = false;
    });
  }

  loadCodebooks () : Promise<any> {
    return new Promise((resolve, reject) => {
      codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>());
      this.subscribe(codebooksStore, CrudReponse.GetAll).then((e: CodebookModel[]) => {
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  loadTenantStores (enableRouteChanged = false) {
    this.loading = true;
    this.loadTenantUser().then(() => {
      this.detectPermissions();
      this.detectLicence();
      this.detectTwoFactorRequirements();
      this.loadTenantUsers().then(() => {
        this.loadWorkplaces().then(() => {
          this.loadRoles().then(() => {
            this.loadGroups().then(() => {
              this.$nextTick(() => {
                this.loading = false;
                if (enableRouteChanged) {
                  const route = this.getFirstRoute(this.selectedTenant);
                  if (route) {
                    this.$router.push(route);
                  }
                  else {
                    this.$router.push({ name: "UserSettings" });
                  }
                }
              });
            });
          });
        });
      });
    });
  }

  loadTenantUser () : Promise<any> {
    return new Promise((resolve, reject) => {
      const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
      tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>(undefined, [
        { field: "userId", op: "eq", comparand: user.id! }
      ], undefined, false, "me"));
      this.subscribe(tenantUsersStore, CrudReponse.GetAll, "me").then((e: TenantUserModel[]) => {
        authStore.dispatch(AuthAction.SetTenantUser, e[0]);
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  loadTenantUsers () : Promise<any> {
    return new Promise((resolve, reject) => {
      tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>());
      this.subscribe(tenantUsersStore, CrudReponse.GetAll).then((e: TenantUserModel[]) => {
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  loadWorkplaces () : Promise<any> {
    return new Promise((resolve, reject) => {
      workplacesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WorkplaceModel>());
      this.subscribe(workplacesStore, CrudReponse.GetAll).then((e: WorkplaceModel[]) => {
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  loadRoles () : Promise<any> {
    return new Promise((resolve, reject) => {
      rolesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<RoleModel>());
      this.subscribe(rolesStore, CrudReponse.GetAll).then((e: RoleModel[]) => {
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  loadGroups () : Promise<any> {
    return new Promise((resolve, reject) => {
      groupsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<GroupModel>());
      this.subscribe(groupsStore, CrudReponse.GetAll).then((e: RoleModel[]) => {
        resolve(e);
      }).catch((e: any) => {
        reject(e);
      });
    });
  }

  formatDateTime (dateTime: string) {
    return formatDate(dateTime);
  }

  toUserSettings () {
    let tenantId = "";
    if (this.tenantId)
      tenantId = this.tenantId;
    else
      tenantId = "";

    if (tenantId != "")
      this.$router.push({ name: "UserSettings", params: { tenantId: tenantId } });
    else
      this.$router.push({ name: "UserSettings" });
  }
  
  getDataList(): Array<any> {
    const tenantId = authStore.useGetter(AuthGetter.GetTenant);
    if (tenantId && tenantId.length > 0) {
      return [
        { text: this.$i18n.t("menu.dashboard"), to: { name: 'Dashboard', params: { tenantId: tenantId } }, icon: "dashboard.svg", permissions: [ PermissionTypeEnum.PerformAudit ] },
        { text: this.$i18n.t("menu.audit"), to: { name: 'Audits', params: { tenantId: tenantId } }, icon: "audits.svg", permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit, PermissionTypeEnum.PerformAudit ] },
        { text: this.$i18n.t("menu.tasks"), to: { name: 'WorkTasks', params: { tenantId: tenantId } }, icon: "calendar.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan ] },
        { text: this.$i18n.t("menu.responses"), to: { name: 'Responses', params: { tenantId: tenantId } }, icon: "responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response, PermissionTypeEnum.MistakeCorrection ] },
        { text: this.$i18n.t("menu.unfinishedResponses"), to: { name: 'Unanswered', params: { tenantId: tenantId } }, icon: "unanswered-responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response ] },
        { text: this.$i18n.t("menu.issues"), to: { name: 'Issues', params: { tenantId: tenantId } }, icon: "issues.svg", permissions: [ PermissionTypeEnum.Findings ] },
        { text: this.$i18n.t("menu.solvedIssues"), to: { name: 'Measures', params: { tenantId: tenantId } }, icon: "measure.svg", permissions: [ PermissionTypeEnum.Measures ] },
        { text: this.$i18n.t("menu.verification"), to: { name: 'Verification', params: { tenantId: tenantId } }, icon: "resources.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.MeasureVerification ] },
        { text: this.$i18n.t("menu.review"), to: { name: 'Review', params: { tenantId: tenantId } }, icon: "tasks.svg", permissions: [ PermissionTypeEnum.PlanItemReview ] },
        { text: this.$i18n.t("menu.reports"), to: { name: 'ReportByQuestion', params: { tenantId: tenantId } }, icon: "reports.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ] }
      ];
    }
    else {
      return [];
    }
  }

  getFirstRoute (tenantId: string | null): any {
    const routes = this.getDataList();
    for (const route of routes) {
      if (this.hasPermissions(route.permissions)) {
          return { name: route.to.name, params: { tenantId } };
      }
    }
    return null;
  }

  getPageTitle() {
    this.pageTitle = this.$router.currentRoute.meta.title;
  }

  isMobileHeaderShown() {
    if (this.$router.currentRoute.meta.hideMobileHeader)
      this.showMobileHeader = !this.$router.currentRoute.meta.hideMobileHeader;
    else
      this.showMobileHeader = true;
  }

  getStringTranslation (string: string | null | undefined) {
    if (string)
      return this.$i18n.t(string);
    else
      return null;
  }

  setDocumentTitle() {
    if (this.$router.currentRoute.meta.title) {
      const title = this.getStringTranslation(this.$router.currentRoute.meta.title) as string;
      document.title = title+" - Audit Management Software";
    } else {
      document.title = "Audit Management Software";
    }
  }

}
