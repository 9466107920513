


































































































































































































































































































































































































































import { CategoryModel, PlanItemBasicModel, PlanItemModel, ResponseModel, ResponseTypeEnum, TenantUserModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import WorkTaskDialog from '@/libs/work-tasks/components/WorkTaskDialog.vue';
import { responsesStore } from '../+state/store';
import { categoriesStore } from '@/libs/categories/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

@Component({
    components: {
        WorkTaskDialog
    }
})
export default class Responses extends Page {

    filterCategory: Array<string> | null = null;
    filterAudit: Array<string> | null = null;
    filterUser: Array<string> | null = null;
    filterDepartment: Array<string> | null = null;
    filterPosition: Array<string> | null = null;
    filterWorkplace: Array<string> | null = null;
    filterState = "all";
    filterPlanItemState: Array<string> | null = null;
    filterCorrectiveMeasure = 0;
    filterIsImplementationClosed = null;
    filterResponseValues: string[] = [];
    mobileFiltersShow = false;
    escalation = 0;
    pageIndex = 1;
    pageSize = 25;
    pagingChanged = false;
    showScore = false;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    
    get headers() {
        let headers = [
            { text: this.$i18n.t("planItems.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.audit.name"), value: "audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.evidenceNumber"), value: "evidenceNumber", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.category.name"), value: "audit.category.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.user.displayName"), value: "tenantUser.user.displayName", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.workplace.name"), value: "workplace.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.responseCount"), value: "responseCount", align: "left", width: "5%", sortable: false }
        ];

        if ((this.isOkNok || this.isOkNokWarning) && this.showScore)
            headers.push({ text: this.$i18n.t("planItems.okCount"), value: "okCount", align: "left", width: "5%", sortable: false });
        
        if (this.isOkNokWarning && this.showScore)
            headers.push({ text: this.$i18n.t("planItems.okCountWithWarning"), value: "nokCount", align: "left", width: "5%", sortable: false });
        
        if ((this.isOkNok || this.isOkNokWarning) && this.showScore)
            headers.push({ text: this.$i18n.t("planItems.nokCount"), value: "nokCount", align: "left", width: "5%", sortable: false });
        
        if ((this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreZeroToThree || this.isVDA63) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 0", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if ((this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 1", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if ((this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 2", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if ((this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 3", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if ((this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToFive || this.isScoreOneToFour || this.isVDA63) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 4", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if ((this.isScoreZeroToFive || this.isScoreOneToFive) && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 5", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.isVDA63 && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 6", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.isVDA63 && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 8", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.isVDA63 && this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 10", value: "nokCount", align: "left", width: "4%", sortable: false });

        if ((this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreOneToThree || this.isScoreOneToFour))
            headers.push({ text: this.$i18n.t("planItems.scoreValue"), value: "scoreValue", align: "left", sortable: false });
        
        headers.push(
            { text: this.$i18n.t("planItems.notAnswered"), value: "notAnsweredCount", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.successRate"), value: "nokCount", width: "5%", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.planItemState"), value: "planItemState", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        );
        return headers;
    }

    @Prop() tenantId!: string;
    @Ref() dialog!: WorkTaskDialog;

    @Watch("filterPlanItemState")
    filterStateChanged () {
        this.load();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.load();
    }

    get responseValueItems() {
        return [
            { text: this.$i18n.t("responseValue.ok"), value: "ok" },
            { text: this.$i18n.t("responseValue.okWithWarning"), value: "okWithWarning" },
            { text: this.$i18n.t("responseValue.notOk"), value: "notOk" },
            { text: this.$i18n.t("responseValue.scoreZero"), value: "scoreZero" },
            { text: this.$i18n.t("responseValue.scoreOne"), value: "scoreOne" },
            { text: this.$i18n.t("responseValue.scoreTwo"), value: "scoreTwo" },
            { text: this.$i18n.t("responseValue.scoreThree"), value: "scoreThree" },
            { text: this.$i18n.t("responseValue.scoreFour"), value: "scoreFour" },
            { text: this.$i18n.t("responseValue.scoreFive"), value: "scoreFive" },
            { text: this.$i18n.t("responseValue.scoreSix"), value: "scoreSix" },
            { text: this.$i18n.t("responseValue.scoreEight"), value: "scoreEight" },
            { text: this.$i18n.t("responseValue.scoreTen"), value: "scoreTen" },
        ];
    }

    @Watch("filterCategory")
    @Watch("filterAudit")
    @Watch("filterUser")
    @Watch("filterDepartment")
    @Watch("filterPosition")
    @Watch("filterWorkplace")
    @Watch("filterCorrectiveMeasure")
    @Watch("filterIsImplementationClosed")
    @Watch("filterResponseValues")
    filtersChanged () {
        this.pageIndex = 1;
        this.escalation--;
        if (this.escalation <= 0)
            this.load();
    }

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic);
    }
    
    get planItems (): PlanItemBasicModel[] {
        return [...planItemsStore.useGetter(CrudGetter.DataBasic)];
    }

    get totalRows (): number {
        return ({...planItemsStore.useGetter(CrudGetter.DataStatsBasic)} as CrudDataStats)?.rowCount;
    }

    get downloading(): boolean {
        return responsesStore.useGetter(CrudGetter.Downloading);
    }

    get categories (): CategoryModel[] {
        return [...categoriesStore.useGetter(CrudGetter.Data)];
    }

    get isOkNok(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.OkNok);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.OkNok) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isOkNokWarning(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.OkNokWarning);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.OkNokWarning) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToFive(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToFive);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreZeroToFive) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToFour(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToFour);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreZeroToFour) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToOne(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToOne);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreZeroToOne) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToTwo(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToTwo);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreZeroToTwo) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreOneToThree(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToThree);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreOneToThree) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreOneToFive(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToFive);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreOneToFive) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToThree(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToThree);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreZeroToThree) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isScoreOneToFour(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToFour);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.ScoreOneToFour) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get isVDA63(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.VDA63);
        if ((result.length > 0 && this.categoryFilterResponseType && this.categoryFilterResponseType.length > 0 && this.categoryFilterResponseType!.includes(ResponseTypeEnum.VDA63) || this.categoryFilterResponseType == null)) 
            return true;
        else
            return false;
    }

    get categoryFilterResponseType(): Array<string> | null | undefined {
        if (this.filterCategory) {
            let result = this.categories.filter((x: CategoryModel) => this.filterCategory!.includes(x.id!));
            if (result.length > 0) {
                let array: Array<string> = [];
                result.forEach((x: CategoryModel) => {
                    array.push(x.responseType!);
                });
                return array;
            }
            else
                return null;
        } else {
            return null;
        }
    }

    saveQuery () {
        this.setQuery("pageSize", this.pageSize?.toString());
        window.localStorage.ptPageSize = this.pageSize?.toString();
    }

    computeSuccessRate(responseCount: number, okCount: number): string {
        if (responseCount == 0)
            return "100";
        return (okCount / responseCount * 100).toFixed(2);
    }

    computeScoreSuccessRate(scoreValue: number, scoreMaxValue: number): string {
        if (scoreValue == null || scoreMaxValue == null)
            return "--";
        if (scoreMaxValue == 0)
            return "100 %";
        return (scoreValue / scoreMaxValue * 100).toFixed(2) + " %";
    }

    created () {
        if (window.localStorage.showScore)
            this.showScore = JSON.parse(window.localStorage.showScore);
    }

    mounted () {
        if (this.getQueryInt("pageSize") != null) {
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
        }
        else {
            if (window.localStorage.ptPageSize && Number(window.localStorage.ptPageSize)) {
                this.pageSize = Number(window.localStorage.ptPageSize);
            }
            this.saveQuery();
        }
        this.load();
        this.loadCategories();
    }

    load () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        let categoryFilters: CrudQueryPredicate[] = [];
        let auditFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let workplaceFilters: CrudQueryPredicate[] = [];
        let planItemStateFilters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "audit.category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        filters.push({ field: "isAnswered", op: "eq", comparand: true });
        if (this.filterCategory && this.filterCategory.length > 0) {
            this.filterCategory.forEach((e: string) => {
                if (e) {
                    categoryFilters.push({
                        field: "audit.categoryId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterAudit && this.filterAudit.length > 0) {
            this.filterAudit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "auditId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterUser && this.filterUser.length > 0) {
            this.filterUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "tenantUserId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterDepartment && this.filterDepartment.length > 0) {
            this.filterDepartment.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "tenantUser.departmentId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPosition && this.filterPosition.length > 0) {
            this.filterPosition.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "tenantUser.positionId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterWorkplace && this.filterWorkplace.length > 0) {
            this.filterWorkplace.forEach((e: string) => {
                if (e) {
                    workplaceFilters.push({
                        field: "workplaceId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPlanItemState && this.filterPlanItemState.length > 0) {
            this.filterPlanItemState.forEach((e: string) => {
                if (e) {
                    planItemStateFilters.push({
                        field: "planItemState",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterResponseValues.length > 0) {
            const filterResponseValues = {
                field: "id", op: "wrap", comparand: [] as any[]
            };
            for (const filterResponseValue of this.filterResponseValues) {
                let column = "";
                switch (filterResponseValue) {
                    case "ok":
                        column = "OkCount";
                        break;
                    case "okWithWarning":
                        column = "OkWarningCount";
                        break;
                    case "notOk":
                        column = "NokCount";
                        break;
                    case "scoreZero":
                        column = "ScoreZeroCount";
                        break;
                    case "scoreOne":
                        column = "ScoreOneCount";
                        break;
                    case "scoreTwo":
                        column = "ScoreTwoCount";
                        break;
                    case "scoreThree":
                        column = "ScoreThreeCount";
                        break;
                    case "scoreFour":
                        column = "ScoreFourCount";
                        break;
                    case "scoreFive":
                        column = "ScoreFiveCount";
                        break;
                }
                filterResponseValues.comparand.push({ field: column, op: "gt", comparand: 0, junction: "or" });
            }
            filters.push(filterResponseValues);
        }
        if (categoryFilters.length > 0) {
            filters.push({
                field: "audit.categoryId",
                op: "wrap",
                comparand: categoryFilters
            });
        }
        if (auditFilters.length > 0) {
            filters.push({
                field: "auditId",
                op: "wrap",
                comparand: auditFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "tenantUserId",
                op: "wrap",
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "tenantUser.departmentId",
                op: "wrap",
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "tenantUser.positionId",
                op: "wrap",
                comparand: positionFilters
            });
        }
        if (workplaceFilters.length > 0) {
            filters.push({
                field: "workplaceId",
                op: "wrap",
                comparand: workplaceFilters
            });
        }
        if (planItemStateFilters.length > 0) {
            filters.push({
                field: "planItemState",
                op: "wrap",
                comparand: planItemStateFilters
            });
        }
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, this.getFilters(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    open (item: PlanItemModel) {
        this.dialog.open(item.id!, item.auditId!, item.time!, true);
    }

    exportClick () {
        const filters = this.getFilters();
        responsesStore.dispatch(CrudAction.Download, createCrudQueryPayload<ResponseModel>({ field: "time", index: 1, order: "desc" }, filters));
    }

    loadCategories () {
        const filters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        categoriesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CategoryModel>({ field: "name", index: 1, order: "asc" }, filters));
    }

    setShowScore () {
        this.showScore = !this.showScore;
        window.localStorage.showScore = this.showScore.toString();
    }

}
