
























































































































































































































































































































import Page from '@/Page.vue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import WorkTaskKanbanColumn from '@/libs/work-tasks/components/WorkTaskKanbanColumn.vue';
import {WorkTaskKanbanColumnModel} from '@/libs/work-tasks/model/WorkTaskKanbanColumnModel';
import moment from 'moment';
import {ApplicationUserModel, PermissionTypeEnum, PlanItemModel, TenantUserModel} from '@/libs/Api';
import {planItemsStore} from '@/libs/plan-items/+state/store';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';

import {Navigation} from 'swiper';
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {CrudDataStats} from '@/libs/core/+state/models/crud-data-stats';
import WorkTaskDialog from '../components/WorkTaskDialog.vue';
import WorkTaskCreateDialog from '../components/WorkTaskCreateDialog.vue';
import iCalDialog from '../components/iCalDialog.vue';
import {applicationUserStore} from '@/libs/user-settings/+state/store';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';

@Component({
    components: {
        WorkTaskDialog,
        WorkTaskKanbanColumn,
        Swiper,
        SwiperSlide,
        iCalDialog,
        WorkTaskCreateDialog
    },
    directives: {
      swiper: directive
    }
})
export default class WorkTasks extends Page {

    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    kanbanItems: WorkTaskKanbanColumnModel[] = [];
    // tableItems: any[] = [];
    tableHeaders = [
        { text: this.$i18n.t("common.day"), value: "time", align: "left", sortable: false },
        { text: this.$i18n.t("workTask.audit"), value: "party.displayName", align: "left", sortable: false },
        { text: this.$i18n.t("common.user"), value: "party.district.name", align: "left", sortable: false },
        { text: this.$i18n.t("common.workplace"), value: "workplace.name", align: "left", sortable: false },
        { text: this.$i18n.t("planItems.planItemState"), value: "planItemState", align: "left", sortable: false },
        { text: "", value: "id", align: "left", sortable: false },
    ];
    currentDate: any = null;
    partyFilter: string | null = null;
    districtFilter: string | null = null;
    townFilter: string | null = null;
    userFilter: string | null = null;
    dateFilter: any = null; 
    tab = "table";
    since = new Date();
    until = new Date();
    mobileFiltersShow = false;
    pagingChanged = false;
    pageIndex = 1;
    pageSize = 25;
    filterOnlyMine = true;
    filterDepartment: Array<string> | null = null;
    filterPosition: Array<string> | null = null;
    filterTenantUser: Array<string> | null = null;
    deletePlanItemModel: PlanItemModel | null = null;
    deletePlanItemDialog = false
    
    get calendarTitleWeek(): string {
        return moment(this.currentDate).format("W.")+" "+this.$i18n.t("common.week");
    }

    get calendarTitleMonth(): string {
        return moment(this.currentDate).format("YYYY / MM");
    }

    get showFilterOnlyMine() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.contains(PermissionTypeEnum.Plan) || permissions.contains(PermissionTypeEnum.Response);
    }

    get canPerformAudit() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.contains(PermissionTypeEnum.PerformAudit);
    }

    get swiperOptions(): any {
        return {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        }
    }

    items: PlanItemModel[] = [];

    @Watch("filterOnlyMine")
    @Watch("filterDepartment")
    @Watch("filterPosition")
    @Watch("filterTenantUser")
    filtersChanged () {
        if (this.tab == "table") {
            this.loadItems();
        }
        this.saveQuery();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.loadItems();
    }

    get tableItems(): PlanItemModel[] {
        return planItemsStore.useGetter(CrudGetter.DataBasic, "WorkTaskItems-" + moment(this.currentDate).format("YYYY-MM-DD"));
    }

    get totalRows(): number {
        var stats = planItemsStore.useGetter(CrudGetter.DataStatsBasic, "WorkTaskItems-" + moment(this.currentDate).format("YYYY-MM-DD")) as CrudDataStats;
        return stats?.rowCount ?? 0;
    }
    
    get deletingPlanItem(): boolean {
        return planItemsStore.useGetter(CrudGetter.Deleting);
    }

    @Prop() tenantId!: string;
    @Ref() kanbanColumns!: WorkTaskKanbanColumn[];
    @Ref() dialog!: WorkTaskDialog;
    @Ref() calendarDialog!: iCalDialog;
    @Ref() mySwiper!: any;

    @Watch("tab")
    tabChanged (v: string, p: string) {
        if (v != p && v == "kanban") {
            this.makeDays();
        }
        else if (v != p && v == "table") {
            this.loadItems();
        }
        if (v != p) {
            this.saveQuery();
        }
    }

    @Watch("dateFilter")
    dateFilterChanged (v: Date, p: Date) {
        if (!moment(v).isSame(moment(p))) {
            this.currentDate = v;
        }
    }

    @Watch("currentDate")
    currentDateChanged (v: Date) {
        if (this.tab == "kanban") {
            this.makeDays();
        }
        else {
            this.loadItems();
        }
        this.setQuery("date", moment(v).format("YYYY-MM-DD"));
    }

    saveQuery () {
        this.setQuery("party", this.partyFilter);
        this.setQuery("district", this.districtFilter);
        this.setQuery("town", this.townFilter);
        this.setQuery("user", this.filterTenantUser ? this.filterTenantUser!.toString() : "");
        this.setQuery("department", this.filterDepartment ? this.filterDepartment!.toString() : "");
        this.setQuery("position", this.filterPosition ? this.filterPosition!.toString() : "");
        this.setQuery("view", this.tab);
        this.setQuery("pageSize", this.pageSize?.toString());
        this.setQuery("date", moment(this.currentDate).format("YYYY-MM-DD"));
        window.localStorage.wtParty = this.partyFilter ?? "";
        window.localStorage.wtDistrict = this.districtFilter ?? "";
        window.localStorage.wtTown = this.townFilter ?? "";
        window.localStorage.wtUser = this.filterTenantUser?.toString() ?? "";
        window.localStorage.wtDepartment = this.filterDepartment?.toString() ?? "";
        window.localStorage.wtPosition = this.filterPosition?.toString() ?? "";
        window.localStorage.wtView = this.tab;
        window.localStorage.wtPageSize = this.pageSize?.toString();
    }

    clearFilters () {
        this.partyFilter = null;
        this.districtFilter = null;
        this.townFilter = null;
        this.filterTenantUser = null;
        this.filterPosition = null;
        this.filterDepartment = null;
        this.saveQuery();
    }

    created () {
        if (this.getQuery("view") != null || this.getQuery("party") != null || this.getQuery("district") != null || this.getQuery("town") != null || this.getQuery("user") != null || this.getQuery("pageSize") != null || this.getQuery("date") != null) {
            this.tab = this.getQuery("view") ?? "kanban";
            this.partyFilter = this.getQuery("party");
            this.districtFilter = this.getQuery("district");
            this.townFilter = this.getQuery("town");
            this.filterTenantUser = this.getQuery("user") ? this.getQuery("user")!.split(",") : null;
            this.filterDepartment = this.getQuery("department") ? this.getQuery("department")!.split(",") : null;
            this.filterPosition = this.getQuery("position") ? this.getQuery("position")!.split(",") : null;
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
            this.currentDate = moment(this.getQuery("date")).isValid() ? moment(this.getQuery("date")).toDate() : moment().startOf("isoWeek").toDate();
            this.dateFilter = moment(this.getQuery("date")).isValid() ? moment(this.getQuery("date")).toDate() : moment().startOf("isoWeek").toDate();
        }
        else {
            this.tab = window.localStorage.wtView ?? "kanban";
            this.partyFilter = window.localStorage.wtParty ?? null;
            this.districtFilter = window.localStorage.wtDistrict ?? null;
            this.townFilter = window.localStorage.wtTown ?? null;
            this.filterTenantUser = window.localStorage.wtUser.split(",") ?? null;
            this.filterDepartment = window.localStorage.wtDepartment.split(",") ?? null;
            this.filterPosition = window.localStorage.wtPosition.split(",") ?? null;
            if (window.localStorage.wtPageSize && Number(window.localStorage.wtPageSize)) {
                this.pageSize = Number(window.localStorage.wtPageSize);
            }
            this.currentDate = moment().startOf("isoWeek").toDate();
            this.dateFilter = moment().startOf("isoWeek").toDate();
            this.saveQuery();
        }
    }

    mounted () {
        this.makeDays();
        //this.loadItems();
        if (this.mySwiper) {
            const index = moment().isoWeekday() - 1;
            this.mySwiper.$swiper.slideTo(index);
        }
    }
    
    nextWeek () {
        this.currentDate = moment(this.currentDate).add(1, "week").toDate();
    }
    
    prevWeek () {
        this.currentDate = moment(this.currentDate).add(-1, "week").toDate();
    }

    nextMonth () {
        this.currentDate = moment(this.currentDate).add(1, "month").toDate();
    }

    prevMonth () {
        this.currentDate = moment(this.currentDate).add(-1, "month").toDate();
    }

    makeDays () {
        this.kanbanItems = [];
        let since = moment(this.currentDate).toDate();
        const until = moment(since).add(7, "day").toDate();
        this.since = since;
        this.until = until;
        while (since < until) {
            this.kanbanItems.push({
                id: moment(since).format("YYYY-MM-DD"),
                date: since
            } as WorkTaskKanbanColumnModel);
            since = moment(since).add(1, "day").toDate();
        }
    }

    open (item: PlanItemModel) {
        this.dialog.open(item.id!, item!.auditId!, item.time!);
    }

    getPredicates () {
        const filterPredicates = [
            { field: "time", op: "gte", comparand: moment(this.currentDate).startOf("month").toDate() },
            { field: "time", op: "lte", comparand: moment(this.currentDate).add(1, 'year').endOf("month").toDate() }
        ] as any;
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        if (this.filterOnlyMine) {
            filterPredicates.push({
                field: "tenantUserId", op: "eq", comparand: this.tenantUser.id
            });
        }
        else {
            if (this.filterTenantUser && this.filterTenantUser.length > 0) {
                this.filterTenantUser.forEach((e: string) => {
                    if (e) {
                        userFilters.push({
                            field: "tenantUserId",
                            op: "eq",
                            comparand: e,
                            junction: "or"
                        });
                    }
                });
            }
            if (this.filterDepartment && this.filterDepartment.length > 0) {
                this.filterDepartment.forEach((e: string) => {
                    if (e) {
                        departmentFilters.push({
                            field: "tenantUser.departmentId",
                            op: "eq",
                            comparand: e,
                            junction: "or"
                        });
                    }
                });
            }
            if (this.filterPosition) {
                this.filterPosition.forEach((e: string) => {
                    if (e) {
                        positionFilters.push({
                            field: "tenantUser.positionId",
                            op: "eq",
                            comparand: e,
                            junction: "or"
                        });
                    }
                });
            }
        }
        if (userFilters.length > 0) {
            filterPredicates.push({
                field: "tenantUserId",
                op: "wrap",
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filterPredicates.push({
                field: "tenantUser.departmentId",
                op: "wrap",
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filterPredicates.push({
                field: "tenantUser.positionId",
                op: "wrap",
                comparand: positionFilters
            });
        }
        return filterPredicates;
    }

    loadItems () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, createCrudQueryPayload<PlanItemModel>([{field: "time", index: 1, order: "asc"}], this.getPredicates(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        }, false, "WorkTaskItems-" + moment(this.currentDate).format("YYYY-MM-DD")))
    }

    openCalendarDialog () {
        this.calendarDialog.open();
    }
    
    deletePlanItemClick(item: PlanItemModel) {
        this.deletePlanItemDialog = true;
        this.deletePlanItemModel = item;
    }
    
    deletePlanItem() {
        planItemsStore.dispatch(CrudAction.Delete, { id: this.deletePlanItemModel!.id! });
        this.subscribe(planItemsStore, CrudReponse.Delete).then(() => {
            this.deletePlanItemDialog = false;
            this.loadItems();
        });
    }

	workTaskCreated(e: PlanItemModel) {
		if (this.tab == "table") {
			this.loadItems();
		}
		/*else {
			this.kanbanColumns.firstOrDefault(x => moment(x.date).startOf('day').isSame(moment(e.time).startOf('day')))?.refresh();
		}*/
	}

}
