











































































































import { AuditExplanationTypeEnum, AuditModel, PermissionTypeEnum, TenantUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { auditsStore } from '../+state/store';
import AuditCreateDialog from '../components/AuditCreateDialog.vue';
import { isEqual } from "lodash";

@Component({
    components: {
        AuditCreateDialog
    }
})
export default class General extends Page {

    @Prop() tenantId!: string;
    @Prop() auditId!: string;
    districtId: string | null = null;
    auditOriginal: AuditModel | null = null;
    audit: AuditModel | null = null;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    deleteDialog = false;
    deleting = false;
    valid = false;
    loading = true;

    @Ref() form!: any;
    @Ref() auditCreateDialog!: AuditCreateDialog;

    get isReadonly (): boolean {
        const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
        return !this.hasPermissions([PermissionTypeEnum.EditAllAudits]) && !(this.hasPermissions([PermissionTypeEnum.EditOwnedAudit]) && this.audit?.ownerId == tenantUser?.id);
    }

    get saving(): boolean {
        return auditsStore.useGetter(CrudGetter.Saving);
    }

    get hasChanges () {
        return !isEqual(this.auditOriginal, this.audit);
    }

    get enableExplanationByCategory() {
        return this.audit?.category?.useExplanationByCategory == true;
    }

    mounted () {
        this.audit = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
        this.auditOriginal = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
        this.loading = false;
        if (this.audit.explanationType == AuditExplanationTypeEnum.ByCategory && this.audit.category?.useExplanationByCategory != true) {
            this.audit.explanationType = AuditExplanationTypeEnum.None;
            this.save(false);
        }
    }

    save (validate = true) {
        if (!validate || this.form.validate()) {
            const audit = {...this.audit};
            auditsStore.dispatch(CrudAction.Update, { item: audit });
            this.subscribe(auditsStore, CrudReponse.Update).then(() => {
                this.auditOriginal = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
            });
        }
    }

    deleteAuditClick () {
        this.deleteDialog = true;
    }

    deleteAudit () {
        this.deleting = true;
        auditsStore.dispatch(CrudAction.Delete, { id: this.auditId });
        this.subscribe(auditsStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.$router.push({ name: "Audits", params: { tenantId: this.tenantId } });
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

    cloneAudit () {
        this.auditCreateDialog.clone();
    }

}
