


















































































































































































































































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import {
	CategoryModel,
	FindingFileModel,
	FindingModel,
	ImplementationStateEnum,
	MeasureModel,
	MeasureVerificationUserModel,
	MeasureVerificationUserTypeEnum,
	PermissionTypeEnum,
	PlanItemModel,
	ResponseBasicModel,
	ResponseModel,
	TenantUserModel
} from '@/libs/Api';
import WorkTaskFindingItemMeasure from '../components/WorkTaskFindingItemMeasure.vue';
import { cloneDeep } from 'lodash';
import { measuresStore } from '@/libs/measures/+state/store';
import moment from 'moment';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { tenantUsersStore } from '@/libs/tenants/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import WorkTaskDialogFiles from '../components/WorkTaskDialogFiles.vue';

@Component({
    components: {
        WorkTaskFindingItemMeasure,
        WorkTaskDialogFiles
    }
})
export default class WorkTaskMeasureItem extends Page {

    /* Component props */
    @PropSync("measure") item!: MeasureModel;
    @Prop() planItem!: PlanItemModel;
    @Prop() category!: CategoryModel;
    @Prop() finding!: FindingModel;

    /* Component state variables */
    dialog = false;
    valid = true;
    saving = false;
    hasChanges = true;
    dialogValid = true;
    invalidDialogVerificationUsers = false;
    dialogModel: MeasureModel | null = null;
    dialogVerificationUsers: string[] = [];

    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Ref */
    @Ref() dialogForm!: any;

    get isDialogReadonly(): boolean {
        return this.item?.implementationState == ImplementationStateEnum.Verified;
    }

    get storedPlanItem(): PlanItemModel {
        return planItemsStore.useGetter(CrudGetter.Detail, this.item.finding?.response?.planItemId) as PlanItemModel;
    }

	get storedResponse(): ResponseModel | null {
		const planItem = planItemsStore.useGetter(CrudGetter.Detail, this.item.finding?.response?.planItemId) as PlanItemModel;
		return planItem?.responses?.firstOrDefault(x => x.id == this.finding.responseId) ?? null;
	}

	get canSelectVerificationUser(): boolean {
        
        if (this.planItem.audit!.category!.measureVerificationUserType == MeasureVerificationUserTypeEnum.UserSelect) {
            return true;
        }

        const auditPerformerUserId = this.planItem.tenantUserId!;
        const tenantUser = (tenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[]).firstOrDefault(x => x.id == auditPerformerUserId);

        return tenantUser == null;

    }

    get isReadonly(): boolean {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        const hasPersmissionToEdit = permissions.contains(PermissionTypeEnum.Measures);
        const isMeasureImplementer = this.item.implementerUserId == this.tenantUser.id;
        return !hasPersmissionToEdit || !isMeasureImplementer;
    }

    get response(): ResponseBasicModel {
        return this.finding.response!;
    }

    get findingFiles(): FindingFileModel[] {
        return this.finding?.findingFiles ?? [];
    }

    /* Action functions */
    
    openDialog () {
        this.dialogModel = cloneDeep(this.item);
        this.dialogVerificationUsers = this.dialogModel.measureVerificationUsers?.select(x => x.tenantUserId ?? Guid.EmptyGuid()).distinct().toArray() ?? [];
        this.dialogValid = true;
        this.invalidDialogVerificationUsers = false;
        this.dialog = true;
    }

    closeDialog () {
		this.dialog = false;
    }
    
    save () {
        const invalidDialogVerificationUsers =  this.validateDialogVerificationUsers();
        if (this.dialogForm.validate() && !invalidDialogVerificationUsers) {
            this.saving = true;
            const dialogModel = cloneDeep(this.dialogModel);
            if (dialogModel!.implementationState == ImplementationStateEnum.WaitingForVerification && (this.item!.implementationState == ImplementationStateEnum.New || this.item!.implementationState == ImplementationStateEnum.InProgress)) {
                dialogModel!.implementationTime = moment().format("YYYY-MM-DDTHH:mm:ss");
                if (!this.canSelectVerificationUser) {
                    const auditPerformerUserId = this.planItem.tenantUserId!;
                    const tenantUser = (tenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[]).first(x => x.id == auditPerformerUserId);
                    dialogModel!.measureVerificationUsers = [
                        {
                            id: Guid.EmptyGuid(),
                            tenantUserId: tenantUser.id
                        } as MeasureVerificationUserModel
                    ];
                }
            }
            if (this.canSelectVerificationUser) {
                dialogModel!.measureVerificationUsers!.where(x => !this.dialogVerificationUsers.any(n => n == x.tenantUserId)).toArray().forEach(x => {
                    dialogModel!.measureVerificationUsers!.splice(dialogModel!.measureVerificationUsers!.indexOf(x), 1);
                });
                this.dialogVerificationUsers.where(x => !dialogModel!.measureVerificationUsers!.any(n => n.tenantUserId == x)).toArray().forEach(x => {
                    dialogModel!.measureVerificationUsers!.push({
                        id: Guid.EmptyGuid(),
                        tenantUserId: x
                    } as MeasureVerificationUserModel);
                });
            }
            measuresStore.dispatch(CrudAction.Update, { item: dialogModel });
            this.subscribe(measuresStore, CrudReponse.Update).then((e) => {
                this.saving = false;
                this.$emit("planItemChanged");
                this.closeDialog();
            }).catch((e) => {
                this.saving = false;
                console.log(e);
            });
        }
    }

    validateDialogVerificationUsers () {
        if (this.planItem.audit!.category!.measureVerificationUserType == MeasureVerificationUserTypeEnum.AuditPerformer) {
            return false;
        }
        else {  
            this.invalidDialogVerificationUsers = this.dialogModel?.implementationState == ImplementationStateEnum.WaitingForVerification && this.dialogVerificationUsers.length == 0;
            return this.invalidDialogVerificationUsers
        }
    }

}
