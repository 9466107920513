
































































































































































































import { AuditModel, AuditTimingModel, PermissionTypeEnum, QuestionModel, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { auditsStore } from '../+state/store';
import { auditTimingsStore } from '@/libs/audit-timings/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { Guid } from '@/libs/common/functions/guid';
import { cloneDeep, isEqual } from 'lodash';

@Component({})
export default class Plan extends Page {

    @Prop() tenantId!: string;
    @Prop() auditId!: string;
    districtId: string | null = null;
    audit: AuditModel | null = null;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    deleteDialog = false;
    deleting = false;
    valid = false;
    loading = true;
    saving = false;
    auditTimingsToDelete: AuditTimingModel[] = [];
    auditTimings: AuditTimingModel[] = [];
    auditTimingsOriginal: AuditTimingModel[] = [];
    hasChanges = false;
    
    @Ref() form!: any;

    get isReadonly (): boolean {
        return !this.hasPermissions([PermissionTypeEnum.EditAllAudits]);
    }

    @Watch("auditTimings", { deep: true })
    @Watch("auditTimingsOriginal", { deep: true })
    questionGroupsChanged () {
        const changed = !isEqual(this.auditTimingsOriginal, this.auditTimings);
        this.hasChanges = changed || this.auditTimingsToDelete.length > 0;
    }

    mounted () {
        this.audit = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
        this.loading = false;
        this.load();
    }

    load (): Promise<any> {
        return new Promise<any>((resolve, reject) => {
           auditTimingsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<AuditTimingModel>({
                field: "createdAt", index: 1, order: "asc"
            }, [
                { field: "auditId", op: "eq", comparand: this.auditId }
            ]));
            this.subscribe(auditTimingsStore, CrudReponse.GetAll).then((e: AuditTimingModel[]) => {
                this.auditTimings = e;
                this.auditTimingsOriginal = cloneDeep(e);
                this.hasChanges = false;
                resolve(e);
            }).catch((e) => {
                reject(e);
            }); 
        });
    }

    save () {
        if (this.form.validate()) {
            this.saving = true;
            this.deleteAllPromise(this.auditTimingsToDelete).then(() => {
                this.saveAllPromise(this.auditTimings).then(() => {
                    this.load().then(() => {
                        this.auditTimingsToDelete = [];
                        this.saving = false;
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }).catch((e) => {
                this.saving = false;
                throw e;
            });
        }
    }

    deleteAuditClick () {
        this.deleteDialog = true;
    }

    deleteAudit () {
        this.deleting = true;
        auditsStore.dispatch(CrudAction.Delete, { id: this.auditId });
        this.subscribe(auditsStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.$router.push({ name: "Audits", params: { tenantId: this.tenantId } });
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

    addAuditTiming () {
        this.auditTimings.push({
            id: Guid.EmptyGuid(),
            auditId: this.auditId,
            repeatEach: undefined,
            repeatFrequency: RepeatFrequencyEnum.Week,
            onMonday: false,
            onTuesday: false,
            onWednesday: false,
            onThursday: false,
            onFriday: false,
            onSaturday: false,
            onSunday: false,
            dayOfMonth: undefined,
            nextBusinessDayOnHoliday: false
        } as AuditTimingModel)
    }

    deleteAuditTimingClick (auditTiming: AuditTimingModel) {
        if (auditTiming.id != Guid.EmptyGuid()) {
            this.auditTimingsToDelete.push(auditTiming);
        }
    }

    public deleteAllPromise (payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.deleteAllPromisePop(payload, resolve, reject);
        });
    }

    public saveAllPromise (payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.saveAllPromisePop(payload, resolve, reject);
        });
    }

    private deleteAllPromisePop(payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            if (payloadItem.id != Guid.EmptyGuid())  {
                auditTimingsStore.dispatch(CrudAction.Delete, { id: payloadItem.id });
                this.subscribe(auditTimingsStore, CrudReponse.Delete).then((e: any) => {
                    payload.shift();
                    this.deleteAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        }
        else {
            resolve(null);
        }
    }

    private saveAllPromisePop(payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            if (payloadItem.id == Guid.EmptyGuid())  {
                auditTimingsStore.dispatch(CrudAction.Create, { item: payloadItem });
                this.subscribe(auditTimingsStore, CrudReponse.Create).then((e: any) => {
                    payload.shift();
                    this.saveAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
            else {
                auditTimingsStore.dispatch(CrudAction.Update, { item: payloadItem });
                this.subscribe(auditTimingsStore, CrudReponse.Update).then((e: any) => {
                    payload.shift();
                    this.saveAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        }
        else {
            resolve(null);
        }
    }

}
