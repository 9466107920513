

























































































import { CategoryModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { categoriesStore } from '@/libs/categories/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import CategoryDialog from '../components/CategoryDialog.vue';
import { Guid } from '@/libs/common/functions/guid';

@Component({
    components: {
        CategoryDialog
    }
})
export default class Categories extends Page {

    get headers() {
        return [
            { text: this.$i18n.t("categories.name"), value: "name", align: "left", sortable: false },
            { text: this.$i18n.t("categories.correctionManagers"), value: "name", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }
    itemsPerPage = 10;

    @Prop() tenantId!: string;
    @Ref() dialog!: CategoryDialog;

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get loading(): boolean {
        return categoriesStore.useGetter(CrudGetter.Pending);
    }
    
    get categories (): CategoryModel[] {
        return [...categoriesStore.useGetter(CrudGetter.Data)];
    }

    get totalRows (): number {
        return ({...categoriesStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        categoriesStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<CategoryModel>({ field: "name", index: 1, order: "asc" }, this.getFilters());
    }

    open (item: CategoryModel) {
        this.dialog.open(item.id!);
    }

    createItemClick () {
        this.dialog.open(Guid.EmptyGuid());
    }

}
