


































































import {
	FileModel,
	MeasureFileTypeEnum,
	QuestionFileModel,
	ResponseFileModel,
	ResponseFileType,
	ResponseModel,
	TenantModel
} from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { getAccessToken } from 'axios-jwt';
import axios from 'axios';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { FileListTypeEnum } from "../model/FileListTypeEnum";
import imageCompression from 'browser-image-compression';
import QuestionFileItem from "@/libs/audits/components/QuestionFileItem.vue";
import FilePreview from "@/libs/work-tasks/components/FilePreview.vue";
import QuestionFileDetail from "@/libs/audits/components/QuestionFileDetail.vue";
import FilePreviewDialog from "@/libs/work-tasks/components/FilePreviewDialog.vue";

@Component({
	components: {FilePreviewDialog, QuestionFileDetail, FilePreview, QuestionFileItem}
})
export default class WorkTaskDialogFiles extends Page {

    uploadResponse: ResponseModel | null = null;
    maxUploadFileSize = 50;
    snackbarFileTooBig = false;
    files: ResponseFileModel[] = [];
	tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;

	currentPreviewFile: any = {
		fileIndex: 0,
		canPrev: false,
		canNext: false,
		file: null
	};

    @VModel() model!: ResponseFileModel[];
    @Prop({ default: FileListTypeEnum.Response }) fileListType!: FileListTypeEnum;
    @Prop() canEditFiles!: boolean;
    @Prop({ default: true }) showButton!: boolean;
    @Prop({ default: true }) showFiles!: boolean;
    @Prop() responseFileType!: ResponseFileType;
    @Prop() measureFileType!: MeasureFileTypeEnum;

    @Ref() inputFile!: any;
	@Ref() filePreviewDialog!: FilePreviewDialog;

	get imageFiles(): FileModel[] {
		return this.files.where(x => x.file!.contentType == "image/jpeg" || x.file!.contentType == "image/jpg" || x.file!.contentType == "image/png" || x.file!.contentType == "image/webp").select(x => x.file!).toArray();
	}

    @Watch("model")
    modelChanged (v: ResponseFileModel[]) {
        this.files = v;
    }

    mounted () {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        const tenant = (tenantsStore.useGetter(CrudGetter.Data) as TenantModel[]).first(x => x.id == tenantId);
        this.maxUploadFileSize = tenant.maxUploadFileSize ? tenant.maxUploadFileSize : 50;
        this.files = this.model;
    }

    activateFileUpload(): void {
        this.inputFile.click();
    }

    async uploadFile() {
        const fileSize = this.inputFile.files[0].size as number;
        if (fileSize <= this.maxUploadFileSize * 1000000) {
			const fileName = this.inputFile.files[0].name;
			let file = this.inputFile.files[0];
			if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png" || file.type == "image/webp") {
				const options = {
					useWebWorker: false,
					preserveExif: true,
                    maxIteration: 5
				} as any;
                if (this.tenant.imageUploadUseCompression == true) {
					if (this.tenant.imageUploadMaxFileSize != null && !isNaN(this.tenant.imageUploadMaxFileSize) && Number(this.tenant.imageUploadMaxFileSize) > 0) {
						options.maxSizeMB = Number(this.tenant.imageUploadMaxFileSize);
					}
					if (this.tenant.imageUploadMaxResolution != null && !isNaN(this.tenant.imageUploadMaxResolution) && Number(this.tenant.imageUploadMaxResolution) > 0) {
						options.maxWidthOrHeight = Number(this.tenant.imageUploadMaxResolution);
					}
					if (this.tenant.imageUploadQuality != null && !isNaN(this.tenant.imageUploadQuality) && Number(this.tenant.imageUploadQuality) >= 1 && Number(this.tenant.imageUploadQuality) < 100) {
						options.initialQuality = Number(this.tenant.imageUploadQuality) / 100;
					}
				}
                debugger;
				file = await imageCompression(file, options);
                debugger;
			}
			var formData = new FormData();
            formData.append("file", file, fileName);
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            axios.post((window as any).ApiService.baseUrl + "/api/files/upload", formData, {
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((r) => {
                const fileModel = r.data.returnValue as FileModel;
                this.fileUploaded(fileModel);
            });
        }
        else {
            this.snackbarFileTooBig = true;
        }
        this.inputFile.value = "";
    }

    fileUploaded(fileModel: FileModel): void {
        if (this.fileListType == FileListTypeEnum.Response) {
            const responseFileType = this.responseFileType as ResponseFileType;
            this.$emit("uploaded", {
                fileId: fileModel.id,
                file: fileModel,
                type: responseFileType
            });
        }
        else if (this.fileListType == FileListTypeEnum.Finding) {
            this.$emit("uploaded", {
                fileId: fileModel.id,
                file: fileModel
            });
        }
        else if (this.fileListType == FileListTypeEnum.Measure) {
            const measureFileType = this.measureFileType as MeasureFileTypeEnum;
            this.$emit("uploaded", {
                fileId: fileModel.id,
                file: fileModel,
                type: measureFileType
            });
        }
        else {
            throw "FileListType NotImplemented!";
        }
    }

    downloadFile(file: FileModel): void {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        const token = getAccessToken();
        axios({
            url: (window as any).ApiService.baseUrl + "/api/files/" + file.id! + "/download",
            method: 'GET',
            responseType: 'blob', // important,
            headers: {
                "TenantId": tenantId,
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.fileName!);
            document.body.appendChild(link);
            link.click();
        });
    }

    removeFile(file: ResponseFileModel): void {
        this.$emit("removed", file);
    }

	showFileDetail(fileIndex: number): void {
		this.currentPreviewFile = {
			fileIndex: fileIndex,
			canPrev: fileIndex > 0,
			canNext: fileIndex + 1 < this.imageFiles.length,
			file: this.imageFiles![fileIndex]
		};
		if (this.currentPreviewFile)
			this.filePreviewDialog.open(this.currentPreviewFile);
	}

	prevQuestionFile(): void {
		let targetFile: QuestionFileModel | null = null;
		targetFile = this.imageFiles![this.currentPreviewFile.fileIndex - 1];
		if (targetFile) {
			this.currentPreviewFile.file = targetFile;
			this.currentPreviewFile.fileIndex--;
			this.currentPreviewFile.canPrev = this.currentPreviewFile.fileIndex > 0;
			this.currentPreviewFile.canNext = true;
		}
	}

	nextQuestionFile(): void {
		let targetFile: QuestionFileModel | null = null;
		targetFile = this.imageFiles![this.currentPreviewFile.fileIndex + 1];
		if (targetFile) {
			this.currentPreviewFile.file = targetFile;
			this.currentPreviewFile.fileIndex++;
			this.currentPreviewFile.canPrev = this.currentPreviewFile.fileIndex > 0;
			this.currentPreviewFile.canNext = this.currentPreviewFile.fileIndex + 1 < this.imageFiles;
		}
	}

}
